import { useEffect } from 'react';
import { DrawerBody, DrawerToolbar, joinClassNames } from '@estimateone/frontend-components';
import {
  DrawerEvent,
  UNIVERSAL_PROFILE_DRAWER_READY_EVENT,
  UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID,
} from '@builder/pages/Profiles/UniversalProfileDrawer';
import {
  AccountStatusColumn,
  CompanyHeader,
  CompanyInfo,
  CompanyNameLocation,
} from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerHeader';
import { DrawerLoadingSpinner } from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerLoadingSpinner';
import { AccountActivityInfo } from './components/AccountActivityInfo';
import { AccountTypeTag } from './components/AccountTypeTag';
import { CompanyLogo } from './components/CompanyLogo';
import { CompanyNameHeader } from './components/CompanyNameHeader';
import { ConnectedCompanyBanner } from './components/ConnectedCompanyBanner';
import { AddressBookCompanyToolbar } from './components/Toolbars/AddressBookCompanyToolbar';
import { UniversalSyncedProfiles } from './providers/UniversalProfileProvider';
import { useCompanyLogoURL } from './providers/UniversalProfileProvider/hooks';
import {
  ConnectedBuilderNetworkCompanyFragment,
  ConnectedE1NetworkCompanyFragment,
} from './providers/UniversalProfileProvider/queries.generated';
import styles from './styles.scss';

export const ConnectedCompanyDrawerContent = ({
  universalProfile,
  networkCompany,
  hasProcurementPhaseFeature,
}: {
  readonly networkCompany:
    | ConnectedE1NetworkCompanyFragment
    | ConnectedBuilderNetworkCompanyFragment;
  readonly universalProfile: UniversalSyncedProfiles;
  readonly hasProcurementPhaseFeature: boolean;
}) => {
  const url = useCompanyLogoURL();

  const { connectedProfile, profile } = universalProfile;
  const companyId = Number(connectedProfile.id);
  const profileId = profile.id;

  const accountType = profile?.account?.type;

  const profileDefaultOffice = profile?.offices.find(({ isDefaultOffice }) => isDefaultOffice);
  const addressBookDefaultOffice = connectedProfile?.offices.find(
    ({ isDefaultOffice }) => isDefaultOffice,
  );

  const companyName = profile?.name ?? '';
  const shortAddress = (addressBookDefaultOffice ?? profileDefaultOffice)?.address.shortAddress;
  const altCompanyName = profile.name !== connectedProfile.name ? connectedProfile.name : undefined;

  // tell Jquery that it's time to render the body into the specified div
  useEffect(() => {
    if (profileId && companyId) {
      // This event will cause the jQuery code in vcard_slider.js to make a call to Ascension to
      // render the twig template with the tab container, and then inject that rendered HTML into
      // the container below. It needs to happen after this component has finished rendering.
      const eventData: DrawerEvent = {
        detail: {
          companyId,
          profileId,
        },
      };
      document.dispatchEvent(new CustomEvent(UNIVERSAL_PROFILE_DRAWER_READY_EVENT, eventData));
    }
  }, [profileId, companyId]);

  return (
    <>
      <DrawerToolbar>
        <AddressBookCompanyToolbar
          networkCompany={networkCompany}
          hasProcurementPhaseFeature={hasProcurementPhaseFeature}
        />
      </DrawerToolbar>
      <DrawerBody className={joinClassNames(styles.noPadding, styles.drawerBodyContainer)}>
        <ConnectedCompanyBanner />
        <AccountActivityInfo profile={profile} />
        <CompanyHeader>
          <CompanyLogo url={url ?? null} companyName={companyName} />
          <CompanyInfo>
            <CompanyNameLocation>
              <CompanyNameHeader
                connected
                companyName={companyName}
                altCompanyName={altCompanyName}
              />
              {shortAddress ? <div>{shortAddress}</div> : null}
            </CompanyNameLocation>
            <AccountStatusColumn>
              {accountType ? <AccountTypeTag accountType={accountType} /> : null}
            </AccountStatusColumn>
          </CompanyInfo>
        </CompanyHeader>
        <div
          data-testid={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          id={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          data-company-id={companyId}
          className={styles.drawerTabContainer}
        >
          <DrawerLoadingSpinner />
        </div>
      </DrawerBody>
    </>
  );
};
