import { gql } from '@apollo/client';

export const GET_COMPANIES_BY_NAME = gql`
  query GetCompaniesByName($query: String!) {
    searchCompanyNames(query: $query) {
      id
      name
    }
  }
`;
