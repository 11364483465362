/* eslint-disable react/jsx-key */
import { useMemo } from 'react';
import {
  useExpanded,
  useTable,
  Column as RTColumn,
  CellProps as RTCellProps,
  UseExpandedRowProps,
  TableState as RTTableState,
  useSortBy,
  UseSortByColumnProps,
} from 'react-table';
import {
  Icon,
  IconName,
  Tag,
  TagVariant,
  FolderIcon,
  FolderOpenIcon,
} from '@estimateone/frontend-components';
import { StageDocumentIntegrationType } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type TableRowItem = {
  id: EntityId;
  name: string;
  subRows?: TableRowItem[];
  supersededDocumentTitle?: string | null;
  drawingNumber?: string | null;
  title?: string | null;
  revision?: string | null;
};

// Polyfills for missing/incorrect react table types
// eslint-disable-next-line react/no-unused-prop-types
type UseExpandedColumnProps<D extends TableRowItem> = { row: UseExpandedRowProps<D> };
type Column<D extends TableRowItem> = RTColumn<D> & { canSort?: boolean };
type TableState<D extends object = { expanded: [] }> = Partial<
  RTTableState<D> & { expanded: EntityId[] }
>;

type CellProps = RTCellProps<TableRowItem> &
  UseExpandedColumnProps<TableRowItem> &
  UseSortByColumnProps<TableRowItem>;

export type Folder = Pick<TableRowItem, 'id' | 'name'> & { subRows: TableRowItem[] };
export type File = Omit<TableRowItem, 'subRows'>;

export const isFolder = (item?: TableRowItem): item is Folder => Array.isArray(item?.subRows);

const FolderCell = ({ row: { original, isExpanded } }: CellProps) => (
  <div className={styles.fileNameCell}>
    {isFolder(original) ? (
      <>
        {isExpanded ? <FolderOpenIcon /> : <FolderIcon />}
        <span>{original.name}</span>
      </>
    ) : (
      false
    )}
  </div>
);

const NameCell = ({ value, row: { original } }: CellProps) => (
  <div className={styles.fileNameCell}>
    <span>{value}</span>
    {!isFolder(original) ? (
      <Tag
        variant={TagVariant.Magic200}
        text={original.supersededDocumentTitle ? 'Revised' : 'New'}
      />
    ) : (
      false
    )}
  </div>
);

const CommonCell = ({ value }: CellProps) => <div>{value?.trim().length ? value : '-'}</div>;

const columns: Column<TableRowItem>[] = [
  {
    id: 'folder',
    disableSortBy: true,
    Cell: FolderCell,
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Filename',
    disableSortBy: false,
    Cell: NameCell,
  },
  {
    accessor: 'supersededDocumentTitle',
    Header: 'Superseded Document',
    disableSortBy: true,
    Cell: CommonCell,
  },
  {
    accessor: 'drawingNumber',
    Header: ' Drawing Number',
    disableSortBy: true,
    Cell: CommonCell,
  },
  {
    accessor: 'title',
    Header: 'Title',
    disableSortBy: true,
    Cell: CommonCell,
  },
  {
    accessor: 'revision',
    Header: 'Revision',
    disableSortBy: true,
    Cell: CommonCell,
  },
];

export type AddendumTableProps = {
  data: TableRowItem[];
  documentIntegrationType: StageDocumentIntegrationType;
};
export const AddendumTable = ({ data, documentIntegrationType }: AddendumTableProps) => {
  const initialState: TableState<TableRowItem> = useMemo(() => {
    const expanded = data.map((row) => row.id);
    return {
      expanded,
      sortBy: [
        {
          id: 'name',
          desc: false,
        },
      ],
    };
  }, [data]);

  const tableInstance = useTable<TableRowItem>(
    {
      columns,
      data,
      initialState,
      defaultCanSort: false,
      disableSortRemove: true,
    },
    useSortBy,
    useExpanded,
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const fileSource =
    documentIntegrationType === StageDocumentIntegrationType.PROCORE ? 'Procore' : 'SharePoint';

  return (
    <>
      <label htmlFor="addendum-files-table">
        Updates from {fileSource} (new files or revisions)
      </label>
      <table id="addendum-files-table" {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.isSorted ? (
                    <span>
                      {column.isSortedDesc ? (
                        <Icon name={IconName.ChevronDown} size="10" marginLeft="small" />
                      ) : (
                        <Icon name={IconName.ChevronUp} size="10" marginLeft="small" />
                      )}
                    </span>
                  ) : (
                    false
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: CellProps['row']) => {
            prepareRow(row);

            return isFolder(row.original) && row.cells.length > 0 ? (
              <tr {...row.getRowProps()} className={styles.expandible}>
                <td
                  {...row.cells[0].getCellProps()}
                  {...row.getToggleRowExpandedProps()}
                  colSpan={row.cells.length}
                >
                  {row.cells[0].render('Cell')}
                </td>
              </tr>
            ) : (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
