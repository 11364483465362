import { useEffect, useState } from 'react';
import $ from 'jquery';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Col,
  CollapsibleSection,
  Row,
} from '@estimateone/frontend-components';
import CompanyActions from '../AddressBookDuplicates/CompanyActions';
import CompanyCard from '../AddressBookDuplicates/CompanyCard';
import ManualMergeActions from './ManualMergeActions';
import ManualMergeSearchCard from './ManualMergeSearchCard';
import { Company } from '@builder/common/AddressBook/hooks/useSearchAddressBookCompanies';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const ManualContactMerge = () => {
  const [companyA, setCompanyA] = useState<Company | null>(null);
  const [companyB, setCompanyB] = useState<Company | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const clearSelection = () => {
    setCompanyA(null);
    setCompanyB(null);
  };

  useEffect(() => {
    $(document).on('clear-merge-cards', clearSelection);
  }, []);

  const toggleManualMerge = () => {
    if (isCollapsed === true) {
      clearSelection();
    }
    setIsCollapsed(!isCollapsed);
  };

  const generateCompanyCard = (
    company: Company | null,
    ignoredId: EntityId | undefined,
    callback: (company: Company) => void,
  ) => {
    if (company === null) {
      return <ManualMergeSearchCard updateCompany={callback} excludedCompanyId={ignoredId} />;
    }
    return <CompanyCard company={company} />;
  };

  const generateActionCard = () => {
    if (companyA === null || companyB === null) {
      return <ManualMergeActions clearSelection={clearSelection} />;
    }
    return (
      <CompanyActions
        originalId={companyA.id}
        duplicateId={companyB.id}
        onDismiss={clearSelection}
        dismissText="Clear"
      />
    );
  };

  return (
    <>
      <div className={styles.manualMergeButton}>
        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          onClick={toggleManualMerge}
        >
          {isCollapsed ? 'Merge duplicates manually' : 'Hide manual merge'}
        </Button>
      </div>
      <CollapsibleSection isCollapsed={isCollapsed}>
        <h3>Merge duplicates manually</h3>
        <p className={styles.grey}>Search for duplicate companies that you would like to merge</p>
        <div className={styles.manualMergeItem}>
          {isCollapsed ? null : (
            <Row>
              <Col span={5}>{generateCompanyCard(companyA, companyB?.id, setCompanyA)}</Col>
              <Col span={2}>{generateActionCard()}</Col>
              <Col span={5}>{generateCompanyCard(companyB, companyA?.id, setCompanyB)}</Col>
            </Row>
          )}
        </div>
      </CollapsibleSection>
    </>
  );
};

export default ManualContactMerge;
