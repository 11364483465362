/* eslint-disable */
// @generated
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  EntityId: { input: number; output: number; }
}

export enum AccountFileType {
  CAPABILITY_STATEMENT = 'CAPABILITY_STATEMENT',
  INSURANCE = 'INSURANCE',
  QUOTE_ADDITIONAL = 'QUOTE_ADDITIONAL'
}

export enum AccountType {
  ADMIN = 'ADMIN',
  ARCHITECT = 'ARCHITECT',
  BUILDER = 'BUILDER',
  E1 = 'E1',
  ENGINEER = 'ENGINEER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPPLIER = 'SUPPLIER'
}

export interface AddBuilderContactInput {
  contactTypes: Array<ContactType>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isPreferred: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  networkCompanyId: Scalars['EntityId']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
}

export interface AddContactToPackageInput {
  contactId: Scalars['EntityId']['input'];
  packageId: Scalars['EntityId']['input'];
  quotesDueAt: Scalars['DateTime']['input'];
}

export interface AddNoteToLettingScheduleDetailsInput {
  isCritical: CriticalStatus;
  packageId: Scalars['EntityId']['input'];
  text: Scalars['String']['input'];
}

export interface AddNoteToQuoteInput {
  id: Scalars['EntityId']['input'];
  note: Scalars['String']['input'];
}

export interface AddressBookContactDeleteInput {
  id: Scalars['EntityId']['input'];
}

export interface AddressBookContactSetPreferredInput {
  id: Scalars['EntityId']['input'];
  isPreferred: Scalars['Boolean']['input'];
}

export enum AddressBookDataSource {
  BUILDER_AND_PROFILE = 'BUILDER_AND_PROFILE',
  BUILDER_CREATED = 'BUILDER_CREATED',
  IMPORT = 'IMPORT',
  ITP = 'ITP',
  NETWORK_INVITATION_PROFILE = 'NETWORK_INVITATION_PROFILE',
  PROFILE = 'PROFILE',
  RFQ = 'RFQ',
  SHADOW_PROFILE = 'SHADOW_PROFILE'
}

export interface AppendUploadSessionInput {
  files: Array<SessionFileInput>;
  id: Scalars['EntityId']['input'];
}

export interface AssignQuotePackageInput {
  packageId: Scalars['EntityId']['input'];
  quoteId: Scalars['EntityId']['input'];
}

export enum BusinessEntityType {
  REGISTERED_BUSINESS = 'REGISTERED_BUSINESS',
  SOLE_TRADER = 'SOLE_TRADER'
}

export enum BusinessIdentityNumberErrorCodes {
  COMPANY_IDENTIFIER_NOT_FOUND = 'COMPANY_IDENTIFIER_NOT_FOUND',
  NOT_SUPPORTED_COUNTRY = 'NOT_SUPPORTED_COUNTRY',
  TECHNICAL_ISSUE = 'TECHNICAL_ISSUE'
}

export interface CommitUncategorisedQuoteInput {
  files: Array<UncommittedFileInput>;
  stageId: Scalars['EntityId']['input'];
}

export interface CompleteBuilderQuoteInput {
  additionalFiles: Array<UncommittedFileInput>;
  amount: Scalars['Float']['input'];
  confirmFixedPrice?: InputMaybe<Scalars['Boolean']['input']>;
  contactId: Scalars['EntityId']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['EntityId']['input'];
  quoteId: Scalars['EntityId']['input'];
}

export enum ConnectionType {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED'
}

export enum ConsultantDiscipline {
  ARCHITECT = 'ARCHITECT',
  ARCHITECT_INTERIOR = 'ARCHITECT_INTERIOR',
  ARCHITECT_LANDSCAPE = 'ARCHITECT_LANDSCAPE',
  CONSULTANT_FIRE = 'CONSULTANT_FIRE',
  CONSULTANT_LIGHTING = 'CONSULTANT_LIGHTING',
  CONSULTANT_SUSTAINABILITY = 'CONSULTANT_SUSTAINABILITY',
  CONSULTANT_TECHNOLOGY = 'CONSULTANT_TECHNOLOGY',
  ENGINEER_ACOUSTIC = 'ENGINEER_ACOUSTIC',
  ENGINEER_CIVIL = 'ENGINEER_CIVIL',
  ENGINEER_ELECTRICAL = 'ENGINEER_ELECTRICAL',
  ENGINEER_FIRE = 'ENGINEER_FIRE',
  ENGINEER_HYDRO = 'ENGINEER_HYDRO',
  ENGINEER_MECH = 'ENGINEER_MECH',
  ENGINEER_STRUCTURAL = 'ENGINEER_STRUCTURAL',
  ENGINEER_THERMAL = 'ENGINEER_THERMAL'
}

export enum ContactType {
  CONSTRUCTION = 'CONSTRUCTION',
  ESTIMATING = 'ESTIMATING'
}

export enum ContractSizeEnum {
  SIZE_1M = 'SIZE_1M',
  SIZE_5M = 'SIZE_5M',
  SIZE_10K = 'SIZE_10K',
  SIZE_10M = 'SIZE_10M',
  SIZE_25M = 'SIZE_25M',
  SIZE_50K = 'SIZE_50K',
  SIZE_50M_PLUS = 'SIZE_50M_PLUS',
  SIZE_100K = 'SIZE_100K',
  SIZE_250K = 'SIZE_250K',
  SIZE_500K = 'SIZE_500K'
}

export enum ContractSizeMaxEnum {
  SIZE_1M = 'SIZE_1M',
  SIZE_5M = 'SIZE_5M',
  SIZE_10M = 'SIZE_10M',
  SIZE_10k = 'SIZE_10k',
  SIZE_25M = 'SIZE_25M',
  SIZE_50M_PLUS = 'SIZE_50M_PLUS',
  SIZE_50k = 'SIZE_50k',
  SIZE_100k = 'SIZE_100k',
  SIZE_250k = 'SIZE_250k',
  SIZE_500k = 'SIZE_500k'
}

export enum ContractSizeMinEnum {
  SIZE_0 = 'SIZE_0',
  SIZE_1M = 'SIZE_1M',
  SIZE_5M = 'SIZE_5M',
  SIZE_10M = 'SIZE_10M',
  SIZE_10k = 'SIZE_10k',
  SIZE_25M = 'SIZE_25M',
  SIZE_50M_PLUS = 'SIZE_50M_PLUS',
  SIZE_50k = 'SIZE_50k',
  SIZE_100k = 'SIZE_100k',
  SIZE_250k = 'SIZE_250k',
  SIZE_500k = 'SIZE_500k'
}

export interface CreatePackageWithLettingScheduleDetailsInput {
  stageId: Scalars['EntityId']['input'];
  startOnSiteDate?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  tradeDisciplineId?: InputMaybe<Scalars['EntityId']['input']>;
}

export interface CreateQuoteFromRfqInput {
  additionalFiles: Array<UncommittedFileInput>;
  amount: Scalars['Float']['input'];
  confirmFixedPrice?: InputMaybe<Scalars['Boolean']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  quoteFile: UncommittedFileInput;
  rfqId: Scalars['EntityId']['input'];
}

export interface CreateSessionInput {
  category: UploadCategory;
  files: Array<SessionFileInput>;
  /** The id of the entity that holds the manager key */
  parentId: Scalars['EntityId']['input'];
  type: Scalars['String']['input'];
}

export interface CreateStageProcoreMapInput {
  files: Array<Scalars['String']['input']>;
  folders: Array<Scalars['String']['input']>;
  includeDrawings: Scalars['Boolean']['input'];
  includeSpecifications: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  stageId: Scalars['EntityId']['input'];
}

export interface CreateStageSharepointMapInput {
  baseFolderId: Scalars['String']['input'];
  driveId: Scalars['String']['input'];
  files: Array<Scalars['String']['input']>;
  folders: Array<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  stageId: Scalars['EntityId']['input'];
}

export enum CreditCardCompany {
  AMEX = 'AMEX',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA'
}

export enum CriticalStatus {
  IS_CRITICAL = 'IS_CRITICAL',
  NO_CHANGE = 'NO_CHANGE'
}

export interface DeletePackageInput {
  packageId: Scalars['EntityId']['input'];
}

export interface DeletePendingAddendumInput {
  addendumId: Scalars['EntityId']['input'];
  stageId: Scalars['EntityId']['input'];
}

export interface DeleteQuoteInput {
  id: Scalars['EntityId']['input'];
}

export interface DistanceFromInput {
  distance: Scalars['Int']['input'];
  location: LocationInput;
}

export interface DownloadedPackagesInput {
  addendumId?: InputMaybe<Scalars['EntityId']['input']>;
  stageId: Scalars['EntityId']['input'];
}

export interface EditBuilderContactInput {
  contactTypes: Array<ContactType>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id: Scalars['EntityId']['input'];
  isPreferred: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
}

export interface EditConnectedOrMergedContactInput {
  contactTypes: Array<ContactType>;
  id: Scalars['EntityId']['input'];
  isPreferred: Scalars['Boolean']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  preferredFirstName?: InputMaybe<Scalars['String']['input']>;
  preferredLastName?: InputMaybe<Scalars['String']['input']>;
  preferredPhone?: InputMaybe<Scalars['String']['input']>;
}

export interface EditMergedContactInput {
  contactTypes: Array<ContactType>;
  id: Scalars['EntityId']['input'];
  isPreferred: Scalars['Boolean']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  preferredFirstName?: InputMaybe<Scalars['String']['input']>;
  preferredLastName?: InputMaybe<Scalars['String']['input']>;
  preferredPhone?: InputMaybe<Scalars['String']['input']>;
}

export enum ExperimentFilter {
  USER = 'USER'
}

export enum ExperimentName {
  ADOPT_LITE_TO_ESSENTIAL_UPGRADE = 'ADOPT_LITE_TO_ESSENTIAL_UPGRADE',
  BLD_REQUEST_PROFILE_UPDATE = 'BLD_REQUEST_PROFILE_UPDATE',
  BUILDER_DOC_RENAME_WIZARD = 'BUILDER_DOC_RENAME_WIZARD',
  BUILDER_MENTION_TEAMMATES_IN_NOTES = 'BUILDER_MENTION_TEAMMATES_IN_NOTES',
  BUILDER_REDACTED_SUBBIE_PROFILE = 'BUILDER_REDACTED_SUBBIE_PROFILE',
  BUILDER_SHAREPOINT_INTEGRATION = 'BUILDER_SHAREPOINT_INTEGRATION',
  /** @deprecated internal */
  BUILDER_TEST = 'BUILDER_TEST',
  BUILDER_VISUAL_DOC_DIFFS = 'BUILDER_VISUAL_DOC_DIFFS',
  /** @deprecated no longer in use */
  CHARGEBEE_CHECKOUT_EXPERIENCE = 'CHARGEBEE_CHECKOUT_EXPERIENCE',
  CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW = 'CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW',
  DISCOVER_LEADS_MGT = 'DISCOVER_LEADS_MGT',
  DISCOVER_LEADS_SPECIFINDER = 'DISCOVER_LEADS_SPECIFINDER',
  DISCOVER_LEADS_VIEWED_QUOTE = 'DISCOVER_LEADS_VIEWED_QUOTE',
  DISCOVER_LEADS_VIEW_OTHER_BUILDERS = 'DISCOVER_LEADS_VIEW_OTHER_BUILDERS',
  DISCOVER_QUOTES_VIEWED_QUOTE = 'DISCOVER_QUOTES_VIEWED_QUOTE',
  DISCOVER_SUNSET_INVITATIONS_BANNER = 'DISCOVER_SUNSET_INVITATIONS_BANNER',
  FUSION_SUBBIE_CHAS_TRIAL = 'FUSION_SUBBIE_CHAS_TRIAL',
  ITP_OTP_AUTHENTICATOR = 'ITP_OTP_AUTHENTICATOR',
  NEW_IN_SUBBIE_CREDIT_EXPERIMENT = 'NEW_IN_SUBBIE_CREDIT_EXPERIMENT',
  PPT_CHARGEBEE_FREE_TO_PAID_FLOW = 'PPT_CHARGEBEE_FREE_TO_PAID_FLOW',
  PRISM_CHARGEBEE_RETENTION_MODULE = 'PRISM_CHARGEBEE_RETENTION_MODULE',
  SPECIFINDER_TRIAL_WITH_ONE_KEYWORD = 'SPECIFINDER_TRIAL_WITH_ONE_KEYWORD',
  SUBBIE_ACCOUNT_ONBOARDING_FORM = 'SUBBIE_ACCOUNT_ONBOARDING_FORM',
  SUBBIE_ACTIVITY_FEED = 'SUBBIE_ACTIVITY_FEED',
  SUBBIE_CORE_TRADES = 'SUBBIE_CORE_TRADES',
  SUBBIE_NETWORK_ENROLMENT_FORM = 'SUBBIE_NETWORK_ENROLMENT_FORM',
  SUBBIE_PRICING_TREATMENT_1 = 'SUBBIE_PRICING_TREATMENT_1',
  SUBBIE_PRICING_TREATMENT_2 = 'SUBBIE_PRICING_TREATMENT_2',
  SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS = 'SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS',
  SUBBIE_SPECIFINDER_INVITATIONS = 'SUBBIE_SPECIFINDER_INVITATIONS',
  /** @deprecated internal */
  SUBBIE_TEST = 'SUBBIE_TEST',
  SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE = 'SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE',
  SUPPLIER_FIFTY_REDACTED = 'SUPPLIER_FIFTY_REDACTED',
  SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API = 'SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API',
  SUPPLIER_PRICING_TREATMENT_1 = 'SUPPLIER_PRICING_TREATMENT_1',
  SUPPLIER_PRICING_TREATMENT_2 = 'SUPPLIER_PRICING_TREATMENT_2',
  SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY = 'SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY',
  SUPPLIER_SSC_TRIAL = 'SUPPLIER_SSC_TRIAL'
}

export enum FeatureName {
  AB_AUDIT = 'AB_AUDIT',
  AB_CARDDAV = 'AB_CARDDAV',
  AB_ENABLED = 'AB_ENABLED',
  AB_MULTIPLE_CONTACT_TYPES_ENABLED = 'AB_MULTIPLE_CONTACT_TYPES_ENABLED',
  ACCOUNT_USERS_RESTRICT = 'ACCOUNT_USERS_RESTRICT',
  ACTION_RFQ_ENABLED = 'ACTION_RFQ_ENABLED',
  ADDRESS_BOOK_APP = 'ADDRESS_BOOK_APP',
  ADVANCED_PROJECT_DETAILS = 'ADVANCED_PROJECT_DETAILS',
  ADVANCED_SEARCH = 'ADVANCED_SEARCH',
  ADVANCED_SPEC_SEARCH = 'ADVANCED_SPEC_SEARCH',
  BUILDER_DIRECTORY = 'BUILDER_DIRECTORY',
  BUILDER_FIXED_PRICE_QUOTES = 'BUILDER_FIXED_PRICE_QUOTES',
  BUILDER_INCOGNITO_ENABLED = 'BUILDER_INCOGNITO_ENABLED',
  BUILDER_PROFILE = 'BUILDER_PROFILE',
  BUILDER_QUOTES = 'BUILDER_QUOTES',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COMPANY_PROFILE_ADVANCED = 'COMPANY_PROFILE_ADVANCED',
  /** Legacy construction feature */
  CONSTRUCTION_CORRESPONDENCE = 'CONSTRUCTION_CORRESPONDENCE',
  /** Legacy construction feature */
  CONSTRUCTION_DELIVERY = 'CONSTRUCTION_DELIVERY',
  /** Legacy construction feature */
  CONSTRUCTION_MODE_ENABLED = 'CONSTRUCTION_MODE_ENABLED',
  CONSULTANT_DETAILS = 'CONSULTANT_DETAILS',
  CONSULTANT_INSIGHTS = 'CONSULTANT_INSIGHTS',
  CREDITS_LITE_TIER = 'CREDITS_LITE_TIER',
  DIRECTORY_ENABLED = 'DIRECTORY_ENABLED',
  ENTERPRISE_SSO = 'ENTERPRISE_SSO',
  ITP_ENABLED = 'ITP_ENABLED',
  ITP_REBRANDING_ENABLED = 'ITP_REBRANDING_ENABLED',
  LEADS_MANAGEMENT = 'LEADS_MANAGEMENT',
  MARKET_INSIGHTS = 'MARKET_INSIGHTS',
  MORE_RESTRICTIVE_BUDGET_PAYWALL = 'MORE_RESTRICTIVE_BUDGET_PAYWALL',
  NETWORK_CONNECTIONS = 'NETWORK_CONNECTIONS',
  NETWORK_SEARCH = 'NETWORK_SEARCH',
  NETWORK_UNIFIED_PROFILE = 'NETWORK_UNIFIED_PROFILE',
  NETWORK_VIEW_MARKET_STATISTICS_ENABLED = 'NETWORK_VIEW_MARKET_STATISTICS_ENABLED',
  OMIT_COMPETITOR_DETAILS = 'OMIT_COMPETITOR_DETAILS',
  PROCUREMENT_PHASE_ENABLED = 'PROCUREMENT_PHASE_ENABLED',
  PRODUCT_INSIGHTS_REPORT = 'PRODUCT_INSIGHTS_REPORT',
  PROJECT_COVER_SHEETS = 'PROJECT_COVER_SHEETS',
  /** @deprecated no longer in use */
  PROJECT_SPEC_SEARCH_MAX = 'PROJECT_SPEC_SEARCH_MAX',
  PROJECT_VIEW_AWARDED_ENABLED = 'PROJECT_VIEW_AWARDED_ENABLED',
  PROJECT_VIEW_TENDERERS = 'PROJECT_VIEW_TENDERERS',
  PROJECT_VIEW_VALUE_MAX = 'PROJECT_VIEW_VALUE_MAX',
  QUOTE_VIEWED_BY_BUILDER = 'QUOTE_VIEWED_BY_BUILDER',
  QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK = 'QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK',
  RFQ_ENGAGEMENT = 'RFQ_ENGAGEMENT',
  SPECIFICATION_INSIGHTS = 'SPECIFICATION_INSIGHTS',
  SPECIFINDER_ADVANCED_SEARCH = 'SPECIFINDER_ADVANCED_SEARCH',
  SPECIFINDER_CAN_SEE_UPGRADE_HOOK = 'SPECIFINDER_CAN_SEE_UPGRADE_HOOK',
  SPECIFINDER_KEYWORD_VARIATIONS = 'SPECIFINDER_KEYWORD_VARIATIONS',
  SPECIFINDER_SAVED_KEYWORDS = 'SPECIFINDER_SAVED_KEYWORDS',
  SSC_5_CREDIT = 'SSC_5_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_10_CREDIT = 'SSC_10_CREDIT',
  SSC_15_CREDIT = 'SSC_15_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_20_CREDIT = 'SSC_20_CREDIT',
  SSC_20_TOP_UP_CREDIT = 'SSC_20_TOP_UP_CREDIT',
  SSC_30_CREDIT = 'SSC_30_CREDIT',
  SSC_50_CREDIT = 'SSC_50_CREDIT',
  SSC_50_TOP_UP_CREDIT = 'SSC_50_TOP_UP_CREDIT',
  /** @deprecated no longer in use, but still in the db */
  STAGE_FILES_ENABLED = 'STAGE_FILES_ENABLED',
  SUBBIE_ADDONS = 'SUBBIE_ADDONS',
  SUBBIE_PROFILE = 'SUBBIE_PROFILE',
  SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT = 'SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT',
  SUBBIE_SEE_ALL_TENDERING_BUILDERS = 'SUBBIE_SEE_ALL_TENDERING_BUILDERS',
  TENDER_CALENDAR_SYNC = 'TENDER_CALENDAR_SYNC',
  TENDER_CREATE_PRIVATE_ENABLED = 'TENDER_CREATE_PRIVATE_ENABLED',
  TENDER_CREATE_PUBLIC_ENABLED = 'TENDER_CREATE_PUBLIC_ENABLED',
  TENDER_HANDOVER = 'TENDER_HANDOVER',
  TENDER_INVITE_MAX = 'TENDER_INVITE_MAX',
  TENDER_MARKET_VIEW = 'TENDER_MARKET_VIEW',
  TENDER_NOTICEBOARD_ENABLED = 'TENDER_NOTICEBOARD_ENABLED',
  TENDER_RETENDER = 'TENDER_RETENDER',
  TENDER_SUGGESTION_ENABLED = 'TENDER_SUGGESTION_ENABLED',
  TENDER_VIEW_MAX = 'TENDER_VIEW_MAX',
  UNREDACT_FIFTY = 'UNREDACT_FIFTY',
  WATCHLIST_ASSIGN_PROJECT_TO_USER = 'WATCHLIST_ASSIGN_PROJECT_TO_USER'
}

export interface FinaliseMatrixChangeInput {
  addendumId?: InputMaybe<Scalars['EntityId']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  notifyDownloaded: Scalars['Boolean']['input'];
  notifyNotDownloadedNoResponse: Scalars['Boolean']['input'];
  notifyNotDownloadedResponded: Scalars['Boolean']['input'];
  selectedPackages: Array<Scalars['EntityId']['input']>;
  stageId: Scalars['EntityId']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface GrantRestrictedStageAccessInput {
  stageId: Scalars['EntityId']['input'];
  userIds: Array<Scalars['EntityId']['input']>;
}

export interface HandoverToProcurementStageAddressInput {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['EntityId']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['EntityId']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
}

export interface HandoverToProcurementStageContactInput {
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  contactPhone?: InputMaybe<Scalars['String']['input']>;
}

export interface HandoverToProcurementStageInput {
  address: HandoverToProcurementStageAddressInput;
  budget: StageBudget;
  category: StageCategory;
  contact: HandoverToProcurementStageContactInput;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  internalRef?: InputMaybe<Scalars['String']['input']>;
  lastStageId: Scalars['EntityId']['input'];
  name: Scalars['String']['input'];
  settings: HandoverToProcurementStageSettingsInput;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface HandoverToProcurementStageSettingsInput {
  enableDefaultTimeframes: Scalars['Boolean']['input'];
  fixedPriceQuotes?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotificationsToAwarded: Scalars['Boolean']['input'];
}

export interface InviteContactToPackageInput {
  contactId: Scalars['EntityId']['input'];
  correspondenceType: RfqCorrespondenceType;
  includeScopeOfWorks?: InputMaybe<Scalars['Boolean']['input']>;
  packageId: Scalars['EntityId']['input'];
  personalisedInviteMessage?: InputMaybe<Scalars['String']['input']>;
  quotesDueAt: Scalars['DateTime']['input'];
}

export enum InvoiceStatus {
  CANCELLED = 'CANCELLED',
  NOT_PAID = 'NOT_PAID',
  OUTSTANDING = 'OUTSTANDING',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAYMENT_DUE = 'PAYMENT_DUE',
  PENDING = 'PENDING',
  POSTED = 'POSTED',
  VOIDED = 'VOIDED'
}

export enum LettingScheduleCustomColumnIdentifier {
  CUSTOM_COLUMN_1 = 'CUSTOM_COLUMN_1',
  CUSTOM_COLUMN_2 = 'CUSTOM_COLUMN_2'
}

export enum LettingSchedulePriority {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export interface LocationInput {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
}

export interface MarkNotificationsAsViewedInput {
  topicFilter: Scalars['String']['input'];
  types: Array<NotificationType>;
}

export interface MarkQuoteAsViewedInput {
  id: Scalars['EntityId']['input'];
}

export enum NetworkCompanyState {
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  DENIED = 'DENIED',
  MERGED = 'MERGED'
}

export interface NetworkConnectionSuggestionMutationInput {
  accountProfileId: Scalars['ID']['input'];
  addressBookCompanyId: Scalars['ID']['input'];
  snippets: Array<SnippetInput>;
}

export enum NetworkProfileRequestStatus {
  ACTIONED = 'ACTIONED',
  REQUESTED = 'REQUESTED',
  SENT = 'SENT',
  VIEWED = 'VIEWED'
}

export enum NetworkProfileRequestType {
  UPDATE = 'UPDATE'
}

export enum NotificationType {
  GENERAL = 'GENERAL',
  QUOTE_CREATED = 'QUOTE_CREATED',
  QUOTE_REVISED = 'QUOTE_REVISED'
}

export interface PaginationInput {
  page?: InputMaybe<Scalars['Int']['input']>;
  resultsPerPage?: InputMaybe<Scalars['Int']['input']>;
}

export enum ProjectRedactedReason {
  AWARDED = 'AWARDED',
  BUDGET = 'BUDGET',
  CREDIT_EXPERIMENT = 'CREDIT_EXPERIMENT',
  FIFTY_PERCENT = 'FIFTY_PERCENT',
  SINGLE_TENDERER = 'SINGLE_TENDERER'
}

export enum ProjectStatus {
  AWARDED = 'AWARDED',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export enum QualificationLevel {
  ADVANCED = 'ADVANCED',
  COMMON_ASSESSMENT_STANDARD = 'COMMON_ASSESSMENT_STANDARD',
  ELITE = 'ELITE',
  FOUNDATION = 'FOUNDATION',
  STANDARD = 'STANDARD',
  VERIFIED_SUPPLIER = 'VERIFIED_SUPPLIER'
}

export enum QualificationSource {
  CHAS = 'CHAS'
}

export enum QualificationStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE'
}

export enum QualificationType {
  COMPLIANCE = 'COMPLIANCE',
  MEMBERSHIP = 'MEMBERSHIP'
}

export enum QuoteLogType {
  ADDED = 'ADDED',
  COMPLETED = 'COMPLETED',
  CREATED_FROM_RFQ = 'CREATED_FROM_RFQ',
  DELETED = 'DELETED',
  DOWNLOADED = 'DOWNLOADED',
  DOWNLOADED_ANON = 'DOWNLOADED_ANON',
  DOWNLOADED_BULK_PACKAGE = 'DOWNLOADED_BULK_PACKAGE',
  DOWNLOADED_BULK_STAGE = 'DOWNLOADED_BULK_STAGE',
  DOWNLOADED_BULK_UNCAT = 'DOWNLOADED_BULK_UNCAT',
  FILE_DOWNLOADED = 'FILE_DOWNLOADED',
  FILE_DOWNLOADED_ANON = 'FILE_DOWNLOADED_ANON',
  PACKAGE_ASSIGNED = 'PACKAGE_ASSIGNED',
  REVISED = 'REVISED',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_UNCAT = 'SUBMITTED_UNCAT',
  VIEWED = 'VIEWED'
}

export enum ResultType {
  AB_COMPANY = 'AB_COMPANY',
  BOTH = 'BOTH',
  PROFILE = 'PROFILE'
}

export interface ReviseQuoteInput {
  additionalFiles: Array<UncommittedFileInput>;
  amount: Scalars['Float']['input'];
  confirmFixedPrice?: InputMaybe<Scalars['Boolean']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  quoteFile: UncommittedFileInput;
  quoteId: Scalars['EntityId']['input'];
}

export interface RevokeRestrictedStageAccessInput {
  stageId: Scalars['EntityId']['input'];
  userId: Scalars['EntityId']['input'];
}

export enum RfqCorrespondenceType {
  CONFIRM_QUOTE = 'CONFIRM_QUOTE',
  REQUEST_FOR_QUOTE = 'REQUEST_FOR_QUOTE'
}

export enum RfqExportColumn {
  COMPANY_FAX = 'COMPANY_FAX',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_PHONE = 'COMPANY_PHONE',
  CONTACT_NAME = 'CONTACT_NAME',
  DATE_DOWNLOADED = 'DATE_DOWNLOADED',
  DATE_HARD_COPIES = 'DATE_HARD_COPIES',
  DATE_INVITED = 'DATE_INVITED',
  DATE_QUOTED = 'DATE_QUOTED',
  DOCUMENT_STATUS = 'DOCUMENT_STATUS',
  DOWNLOADED = 'DOWNLOADED',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  HARD_COPIES = 'HARD_COPIES',
  INVITED = 'INVITED',
  OBSOLETE = 'OBSOLETE',
  PACKAGE_ISSUED = 'PACKAGE_ISSUED',
  PHONE = 'PHONE',
  POSTCODE = 'POSTCODE',
  QUOTED = 'QUOTED',
  QUOTE_STATUS = 'QUOTE_STATUS',
  SOURCE = 'SOURCE',
  STATE = 'STATE',
  STREET_ADDRESS = 'STREET_ADDRESS',
  SUBURB = 'SUBURB',
  TRADE = 'TRADE',
  YOUR_NOTES = 'YOUR_NOTES'
}

export enum RfqQuotingStatus {
  DECLINED = 'DECLINED',
  QUOTED = 'QUOTED',
  QUOTING = 'QUOTING'
}

export interface SearchMatchingProfilesInput {
  accountId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
}

export interface SearchNetworkCompaniesInput {
  connectionType?: InputMaybe<ConnectionType>;
  contractSizeMax?: InputMaybe<ContractSizeMaxEnum>;
  contractSizeMin?: InputMaybe<ContractSizeMinEnum>;
  /** Find network companies whose default office is within {{DISTANCE}} of {{LOCATION}}. Avoid using with `serviceLocation` or `serviceArea` */
  distance?: InputMaybe<DistanceFromInput>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Defaults to 200 results, no pagination */
  pagination?: InputMaybe<PaginationInput>;
  projectCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  resultType: ResultType;
  /** Find network companies whose office services the {{SERVICE_AREA}}. Avoid using with `distance` or `serviceLocation` */
  serviceArea?: InputMaybe<ServiceAreaSearchInput>;
  /** Find network companies whose office services the {{LOCATION}}. Avoid using with `distance` or `serviceArea` */
  serviceLocation?: InputMaybe<LocationInput>;
  /** Defaults to build-in multi-factor ranking */
  sorting?: InputMaybe<SortProfilesInput>;
  stockTradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  workforceSize?: InputMaybe<WorkforceSizeEnum>;
}

export interface SearchProfilesAndCompaniesInput {
  connectionType?: InputMaybe<ConnectionType>;
  contractSizeMax?: InputMaybe<ContractSizeMaxEnum>;
  contractSizeMin?: InputMaybe<ContractSizeMinEnum>;
  /** Find network companies whose default office is within {{DISTANCE}} of {{LOCATION}}. Avoid using with `serviceLocation` */
  distance?: InputMaybe<DistanceFromInput>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Defaults to 200 results, no pagination */
  pagination?: InputMaybe<PaginationInput>;
  projectCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  resultType: ResultType;
  /** Find network companies whose default office services the {{LOCATION}}. Avoid using with `distance` */
  serviceLocation?: InputMaybe<LocationInput>;
  /** Defaults to build-in multi-factor ranking */
  sorting?: InputMaybe<SortProfilesInput>;
  stockTradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  workforceSize?: InputMaybe<WorkforceSizeEnum>;
}

export enum ServiceAreaBoundaryType {
  COUNTRY = 'COUNTRY',
  STATE = 'STATE'
}

export interface ServiceAreaSearchInput {
  country: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
}

export enum ServiceAreaType {
  BOUNDARY = 'BOUNDARY',
  RADIAL = 'RADIAL'
}

export interface SessionFileInput {
  directory?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  isArchive: Scalars['Boolean']['input'];
  uuid: Scalars['String']['input'];
}

export interface SetAwardedRfqOnLettingScheduleInput {
  lettingScheduleId: Scalars['EntityId']['input'];
  notifyUnsuccessfulRfqIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  rfqId?: InputMaybe<Scalars['EntityId']['input']>;
}

export interface SetLettingSchedulePriorityInput {
  lettingScheduleId: Scalars['EntityId']['input'];
  priority?: InputMaybe<LettingSchedulePriority>;
}

export interface SetPackageLettingScheduleAssignedToUserInput {
  packageId: Scalars['EntityId']['input'];
  userId?: InputMaybe<Scalars['EntityId']['input']>;
}

export interface SetRfqQuotingStatusInput {
  rfqId: Scalars['EntityId']['input'];
  status?: InputMaybe<RfqQuotingStatus>;
}

export interface SetStageAccessRestrictedInput {
  access: Scalars['Boolean']['input'];
  stageId: Scalars['EntityId']['input'];
}

export enum SharePointDriveItemType {
  FILE = 'FILE',
  FOLDER = 'FOLDER'
}

export interface SnippetInput {
  type: SnippetType;
  value: Array<Scalars['String']['input']>;
}

export enum SnippetType {
  COMPANY_NAME = 'COMPANY_NAME',
  USER_EMAIL = 'USER_EMAIL'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortField {
  CONTRACT_SIZE_MIN = 'CONTRACT_SIZE_MIN',
  DISTANCE = 'DISTANCE',
  LAST_ACTIVE = 'LAST_ACTIVE',
  NAME = 'NAME',
  WORKFORCE_SIZE = 'WORKFORCE_SIZE'
}

export interface SortProfilesInput {
  /** Location must be supplied if sorting by SortField.DISTANCE */
  location?: InputMaybe<LocationInput>;
  sortBy?: InputMaybe<SortField>;
  /** N.b. when sorting by distance, DESC is closest to furthest */
  sortDirection?: InputMaybe<SortDirection>;
}

export enum Source {
  ADDRESS_BOOK = 'ADDRESS_BOOK',
  SUBBIE_DIRECTORY = 'SUBBIE_DIRECTORY'
}

export interface SsoSecretKeyDeleteInput {
  id: Scalars['EntityId']['input'];
}

export interface SsoSecretKeyInput {
  expiresAt: Scalars['DateTime']['input'];
  secret: Scalars['String']['input'];
}

/** Each enum actually represents the UPPER BOUND of a range, not a discrete value */
export enum StageBudget {
  BUDGET_0K = 'BUDGET_0K',
  BUDGET_1B = 'BUDGET_1B',
  BUDGET_2M = 'BUDGET_2M',
  BUDGET_3M = 'BUDGET_3M',
  BUDGET_4M = 'BUDGET_4M',
  BUDGET_5M = 'BUDGET_5M',
  BUDGET_10M = 'BUDGET_10M',
  BUDGET_15M = 'BUDGET_15M',
  BUDGET_20M = 'BUDGET_20M',
  BUDGET_30M = 'BUDGET_30M',
  BUDGET_50M = 'BUDGET_50M',
  BUDGET_100K = 'BUDGET_100K',
  BUDGET_100M = 'BUDGET_100M',
  BUDGET_200M = 'BUDGET_200M',
  BUDGET_250K = 'BUDGET_250K',
  BUDGET_500K = 'BUDGET_500K',
  BUDGET_750K = 'BUDGET_750K',
  BUDGET_1000K = 'BUDGET_1000K',
  BUDGET_1500K = 'BUDGET_1500K',
  BUDGET_UNKNOWN = 'BUDGET_UNKNOWN'
}

export enum StageCategory {
  AGED_CARE = 'AGED_CARE',
  CIVIL = 'CIVIL',
  COMMERCIAL = 'COMMERCIAL',
  CORRECTIONS = 'CORRECTIONS',
  DATA_CENTRE = 'DATA_CENTRE',
  DEFENCE = 'DEFENCE',
  EDUCATION = 'EDUCATION',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FIT_OUT = 'FIT_OUT',
  GOVERNMENT = 'GOVERNMENT',
  HEALTH = 'HEALTH',
  HOSPITALITY = 'HOSPITALITY',
  INDUSTRIAL_HEAVY = 'INDUSTRIAL_HEAVY',
  INDUSTRIAL_LIGHT = 'INDUSTRIAL_LIGHT',
  MEDICAL = 'MEDICAL',
  MIXED_USE = 'MIXED_USE',
  OTHER = 'OTHER',
  PHARMACEUTICAL = 'PHARMACEUTICAL',
  RECREATION = 'RECREATION',
  REFURBISHMENT = 'REFURBISHMENT',
  RESIDENTIAL = 'RESIDENTIAL',
  RETAIL = 'RETAIL',
  TOURISM = 'TOURISM'
}

export enum StageDocumentIntegrationType {
  PROCORE = 'PROCORE',
  SHAREPOINT = 'SHAREPOINT'
}

export enum StageType {
  PROCUREMENT = 'PROCUREMENT',
  TENDER = 'TENDER'
}

export interface UncommittedFileInput {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  hash: Scalars['String']['input'];
}

export interface UniversalProfileInput {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  addressbookCompanyId?: InputMaybe<Scalars['ID']['input']>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
}

export interface UpdateActualAmountInput {
  amount?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['EntityId']['input'];
}

export interface UpdateAwardedStageStatusInput {
  awardedAt: Scalars['DateTime']['input'];
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['EntityId']['input'];
  notifyOption: Scalars['String']['input'];
}

export interface UpdateBudgetAmountInput {
  amount?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['EntityId']['input'];
}

export interface UpdateCompanyDetailsInput {
  companyId: Scalars['ID']['input'];
  companyListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  custom1?: InputMaybe<Scalars['String']['input']>;
  custom2?: InputMaybe<Scalars['String']['input']>;
  custom3?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export interface UpdateCompanyPhoneInput {
  companyId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateDefaultCompanyOfficeInput {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  country?: InputMaybe<Scalars['ID']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['ID']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateLettingColumnStatusesInput {
  stageId: Scalars['EntityId']['input'];
  statuses: Array<UpdateLettingColumnStatusesItemInput>;
}

export interface UpdateLettingColumnStatusesItemInput {
  columnName: Scalars['String']['input'];
  isShown: Scalars['Boolean']['input'];
}

export interface UpdateLettingScheduleCustomColumnNameInput {
  identifier: LettingScheduleCustomColumnIdentifier;
  name?: InputMaybe<Scalars['String']['input']>;
  stageId: Scalars['EntityId']['input'];
}

export interface UpdateLettingScheduleCustomColumnValueInput {
  identifier: LettingScheduleCustomColumnIdentifier;
  lettingScheduleId: Scalars['EntityId']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateLettingTimingsInput {
  letByDate?: InputMaybe<Scalars['DateTime']['input']>;
  letByDateInterval?: InputMaybe<Scalars['Int']['input']>;
  packageId: Scalars['EntityId']['input'];
  quotesDueByDate?: InputMaybe<Scalars['DateTime']['input']>;
  quotesDueByDateInterval?: InputMaybe<Scalars['Int']['input']>;
  sendInvitesByDate?: InputMaybe<Scalars['DateTime']['input']>;
  startOnSiteDate?: InputMaybe<Scalars['DateTime']['input']>;
  startOnSiteDateInterval?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateOtherStageStatusInput {
  id: Scalars['EntityId']['input'];
  notifyInvited: Scalars['Boolean']['input'];
  status: Scalars['Int']['input'];
}

export interface UpdatePackageTitleInput {
  packageId: Scalars['EntityId']['input'];
  title: Scalars['String']['input'];
}

export interface UpdatePendingAddendumDetailsInput {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  stageId: Scalars['EntityId']['input'];
}

export interface UpdateScheduleSettingsInput {
  letByDateInterval?: InputMaybe<Scalars['Int']['input']>;
  quotesDueByDateInterval?: InputMaybe<Scalars['Int']['input']>;
  startOnSiteDateInterval?: InputMaybe<Scalars['Int']['input']>;
  tradeScheduleSettingsId: Scalars['EntityId']['input'];
}

export interface UpdateStageSettingNotifyAwardedInput {
  stageId: Scalars['EntityId']['input'];
  value: Scalars['Boolean']['input'];
}

export interface UpdateStageSharepointMapInput {
  baseFolderId: Scalars['String']['input'];
  driveId: Scalars['String']['input'];
  files: Array<Scalars['String']['input']>;
  folders: Array<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  stageId: Scalars['EntityId']['input'];
}

export enum UploadCategory {
  CORR = 'CORR',
  DOC = 'DOC',
  FILE = 'FILE',
  QUOTE = 'QUOTE',
  QUOTE_EXTRA_FILE = 'QUOTE_EXTRA_FILE'
}

export enum UploadParentType {
  AB_COMPANY = 'AB_COMPANY',
  ADDENDUM = 'ADDENDUM',
  PROJECT_QUOTE = 'PROJECT_QUOTE',
  PROJECT_QUOTE_EXTRA_FILE = 'PROJECT_QUOTE_EXTRA_FILE',
  QUOTE = 'QUOTE',
  QUOTE_EXTRA_FILE = 'QUOTE_EXTRA_FILE',
  RFQ_CORR_ATTACH = 'RFQ_CORR_ATTACH',
  RFQ_CORR_FILE = 'RFQ_CORR_FILE',
  RFQ_QUOTE = 'RFQ_QUOTE',
  RFQ_QUOTE_EXTRA_FILE = 'RFQ_QUOTE_EXTRA_FILE',
  STAGE = 'STAGE',
  STAGE_FILE = 'STAGE_FILE',
  STAGE_QUOTE = 'STAGE_QUOTE',
  STAGE_QUOTE_EXTRA_FILE = 'STAGE_QUOTE_EXTRA_FILE',
  USER_CORR_ATTACH = 'USER_CORR_ATTACH',
  USER_CORR_EMAIL = 'USER_CORR_EMAIL',
  USER_CORR_FILE = 'USER_CORR_FILE'
}

export enum UploadStatus {
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  SKIPPED_DUPLICATE = 'SKIPPED_DUPLICATE',
  SKIPPED_INVALID = 'SKIPPED_INVALID',
  UNZIPPING = 'UNZIPPING'
}

export enum WorkforceSizeEnum {
  RANGE_0_5 = 'RANGE_0_5',
  RANGE_6_20 = 'RANGE_6_20',
  RANGE_21_50 = 'RANGE_21_50',
  RANGE_51_250 = 'RANGE_51_250',
  RANGE_250_PLUS = 'RANGE_250_PLUS',
  RANGE_251_PLUS = 'RANGE_251_PLUS'
}
