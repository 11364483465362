import React, { Dispatch, SetStateAction } from 'react';
import { Chip, ChipGroup, Toggle } from '@estimateone/frontend-components';
import {
  maxOptions,
  minOptions,
} from '@builder/features/SubbieNetworkInvitations/components/FilterDrawer/ContractSizeFilter';
import { WorkforceSizeOpts } from '@builder/features/SubbieNetworkInvitations/components/FilterDrawer/WorkforceSizeFilter';
import { useCategoryOptions } from '@builder/context/SubbieNetworkFilterDataProvider/hooks';
import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
  WorkforceSizeEnum,
} from '@ascension/_gqltypes/builder.generated';
import {
  ContractSize,
  LocationOption,
  SearchAreaOptions,
} from '@builder/common/SubbieNetwork/utils/filters/types';
import styles from './styles.scss';

const determineContractSizeText = (
  contractSizeMin: ContractSizeMinEnum | undefined,
  contractSizeMax: ContractSizeMaxEnum | undefined,
) => {
  const minText = minOptions.find(({ value }) => value === contractSizeMin)?.label;
  const maxText = maxOptions.find(({ value }) => value === contractSizeMax)?.label;
  if (minText == null && maxText == null) {
    return null;
  }
  if (minText != null && maxText == null) {
    return `At least ${minText}`;
  }
  if (minText == null && maxText != null) {
    return `At most ${maxText}`;
  }
  return `${minText} - ${maxText}`;
};

const Divider = () => <div className={styles.divider} />;

const searchAreaText = (
  searchArea: SearchAreaOptions,
  locationLabel: string,
  distanceLabel?: string,
) => {
  if (searchArea === 'service-area') {
    return locationLabel;
  }

  if (searchArea === 'office-location' && distanceLabel) {
    return `Office within ${distanceLabel} of ${locationLabel}`;
  }

  return '';
};

export type FilterChipsProps = {
  readonly onFilterChipClicked: () => void;
  categoryId?: number;
  location?: LocationOption;
  contractSize: ContractSize;
  workforceSize?: WorkforceSizeEnum;
  searchArea?: SearchAreaOptions;
  distanceLabel?: string;
  showE1NetworkResults: boolean;
  handleShowE1NetworkToggle: Dispatch<SetStateAction<boolean>>;
};

export const FilterChips = ({
  onFilterChipClicked,
  categoryId,
  location,
  contractSize,
  workforceSize,
  searchArea,
  distanceLabel,
  showE1NetworkResults,
  handleShowE1NetworkToggle,
}: FilterChipsProps) => {
  const categoryOptions = useCategoryOptions();

  const categoryName =
    categoryId !== null ? categoryOptions?.find(({ value }) => value === categoryId)?.label : null;
  const locationLabel =
    location && searchArea && searchAreaText(searchArea, location.locationLabel, distanceLabel);

  const isContractSizeChipActive = !!contractSize.min || !!contractSize.max;

  const isWorkforceSizeChipActive = !!workforceSize;

  return (
    <ChipGroup>
      <Chip
        active={false}
        className={styles.networkToggleContainer}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleShowE1NetworkToggle(!showE1NetworkResults);
        }}
      >
        <span>Show E1 Network</span>
        <Toggle
          label="E1 Network"
          name="show-e1-network"
          checked={showE1NetworkResults}
          onChange={() => {}}
        />
      </Chip>
      <Divider />
      <Chip active={!!locationLabel} onClick={onFilterChipClicked}>
        <span>Search Area</span>
        <span>{locationLabel}</span>
      </Chip>
      <Chip active={isContractSizeChipActive} onClick={onFilterChipClicked}>
        <span>Contract Size</span>
        <span>{determineContractSizeText(contractSize.min, contractSize.max)}</span>
      </Chip>
      <Chip active={isWorkforceSizeChipActive} onClick={onFilterChipClicked}>
        <span>Employees</span>
        <span>{WorkforceSizeOpts.find(({ value }) => value === workforceSize)?.label}</span>
      </Chip>
      <Chip active={!!categoryId} onClick={onFilterChipClicked}>
        <span>Category</span>
        <span>{categoryName}</span>
      </Chip>
    </ChipGroup>
  );
};
