import { ApprovedInvite } from './types/stage_responses_list.types';
import {
  QualificationLevel,
  QualificationSource,
  QualificationType,
} from '@ascension/_gqltypes/builder.generated';

const qualificationPrompts = (level: QualificationLevel) => {
  switch (level) {
    case QualificationLevel.ELITE:
      return 'CHAS Elite';
    case QualificationLevel.ADVANCED:
      return 'CHAS Advanced';
    case QualificationLevel.STANDARD:
      return 'CHAS Standard';
    case QualificationLevel.FOUNDATION:
      return 'CHAS Foundation';
    case QualificationLevel.VERIFIED_SUPPLIER:
      return 'CHAS Verified Supplier';
    case QualificationLevel.COMMON_ASSESSMENT_STANDARD:
      return 'Common Assessment Standard';
    default:
      return null;
  }
};

export const appendQualifications = (
  obj: Pick<ApprovedInvite, 'qualifications'>,
  $container: JQuery<HTMLElement>,
  qualificationsIconPaths: (level: QualificationLevel) => string | undefined,
) => {
  if (obj?.qualifications?.length > 0) {
    const $qualifications = $('<span class="qualifications">');
    obj.qualifications
      .filter(
        (qualification) =>
          qualification.source === QualificationSource.CHAS &&
          qualification.type === QualificationType.COMPLIANCE,
      )
      .forEach((qualification) => {
        const prompt = qualificationPrompts(qualification.level);
        const iconPath = qualificationsIconPaths(qualification.level);
        if (iconPath) {
          $qualifications.append(
            `<img src="${iconPath}" alt="${prompt}" class="tooltip-trigger" title="${prompt}" />`,
          );
        }
      });
    $container.append($qualifications);
  }
};
