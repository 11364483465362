import { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  ButtonVariant,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  DrawerToolbar,
} from '@estimateone/frontend-components';
import { SubbieNetworkFilterForm } from './SubbieNetworkFilterForm';
import { pluralise } from '@shared/Util/pluralise';
import { SubbieNetworkFilterFormId } from '../../types';
import { WorkforceSizeEnum } from '@ascension/_gqltypes/builder.generated';
import {
  ContractSize,
  LocationOption,
  SearchAreaOptions,
} from '@builder/common/SubbieNetwork/utils/filters/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.module.scss';

type DrawerProps = Parameters<typeof Drawer>[0];
type SubbieNetworkFilterDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  tradeId?: number;
  onSelectTrade: Dispatch<SetStateAction<number | undefined>>;
  stageTradeOptions: ValueLabelOption<number>[] | null;
  categoryId?: number;
  onSelectCategory: Dispatch<SetStateAction<number | undefined>>;
  location: LocationOption | undefined;
  onSetLocation: (location: LocationOption | undefined) => void;
  contractSize: ContractSize;
  onSelectContractSize: Dispatch<SetStateAction<ContractSize | undefined>>;
  workforceSize?: WorkforceSizeEnum;
  onSelectWorkforceSize: Dispatch<SetStateAction<WorkforceSizeEnum | undefined>>;
  searchArea?: SearchAreaOptions;
  onSelectSearchArea: Dispatch<SetStateAction<SearchAreaOptions | undefined>>;
  distance?: number;
  onSetDistance: Dispatch<SetStateAction<number | undefined>>;
  onSetDistanceLabel: Dispatch<SetStateAction<string | undefined>>;
};

const SubbieNetworkFilterDrawer = ({
  isOpen,
  onClose,
  tradeId,
  onSelectTrade,
  stageTradeOptions,
  categoryId,
  onSelectCategory,
  location,
  onSetLocation,
  contractSize,
  onSelectContractSize,
  workforceSize,
  onSelectWorkforceSize,
  searchArea,
  onSelectSearchArea,
  distance,
  onSetDistance,
  onSetDistanceLabel,
}: SubbieNetworkFilterDrawerProps) => {
  const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerToolbar className={styles.filterToolbar} />
        <DrawerBody className={styles.filterBody}>
          <h1 className={styles.filterHeading}>All Filters</h1>
          <SubbieNetworkFilterForm
            setSelectedFiltersCount={setSelectedFiltersCount}
            tradeId={tradeId}
            onSelectTrade={onSelectTrade}
            stageTradeOptions={stageTradeOptions}
            categoryId={categoryId}
            onSelectCategory={onSelectCategory}
            location={location}
            onSetLocation={onSetLocation}
            contractSize={contractSize}
            onSelectContractSize={onSelectContractSize}
            workforceSize={workforceSize}
            onSelectWorkforceSize={onSelectWorkforceSize}
            searchArea={searchArea}
            onSelectSearchArea={onSelectSearchArea}
            distance={distance}
            onSetDistance={onSetDistance}
            onSetDistanceLabel={onSetDistanceLabel}
          />
        </DrawerBody>
        <DrawerFooter className={styles.footerButtonContainer}>
          <Button type="reset" variant={ButtonVariant.Grey} form={SubbieNetworkFilterFormId}>
            Reset Filters
          </Button>
          <Button type="submit" variant={ButtonVariant.Primary} form={SubbieNetworkFilterFormId}>
            Confirm {selectedFiltersCount} {pluralise('Filter', selectedFiltersCount)}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SubbieNetworkFilterDrawer;
