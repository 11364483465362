/* eslint-disable */
// @generated
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  EntityId: { input: number; output: number; }
  DateTime: { input: string; output: string; }
}

export enum AccountType {
  BUILDER = 'BUILDER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPPLIER = 'SUPPLIER',
  ARCHITECT = 'ARCHITECT',
  ENGINEER = 'ENGINEER',
  E1 = 'E1',
  ADMIN = 'ADMIN'
}

export enum FeatureName {
  AB_CARDDAV = 'AB_CARDDAV',
  ADDRESS_BOOK_APP = 'ADDRESS_BOOK_APP',
  BUILDER_INCOGNITO_ENABLED = 'BUILDER_INCOGNITO_ENABLED',
  BUILDER_PROFILE = 'BUILDER_PROFILE',
  BUILDER_QUOTES = 'BUILDER_QUOTES',
  DIRECTORY_ENABLED = 'DIRECTORY_ENABLED',
  /** Legacy construction feature */
  CONSTRUCTION_CORRESPONDENCE = 'CONSTRUCTION_CORRESPONDENCE',
  /** Legacy construction feature */
  CONSTRUCTION_DELIVERY = 'CONSTRUCTION_DELIVERY',
  /** Legacy construction feature */
  CONSTRUCTION_MODE_ENABLED = 'CONSTRUCTION_MODE_ENABLED',
  PROCUREMENT_PHASE_ENABLED = 'PROCUREMENT_PHASE_ENABLED',
  TENDER_CREATE_PRIVATE_ENABLED = 'TENDER_CREATE_PRIVATE_ENABLED',
  TENDER_CREATE_PUBLIC_ENABLED = 'TENDER_CREATE_PUBLIC_ENABLED',
  AB_ENABLED = 'AB_ENABLED',
  AB_AUDIT = 'AB_AUDIT',
  AB_MULTIPLE_CONTACT_TYPES_ENABLED = 'AB_MULTIPLE_CONTACT_TYPES_ENABLED',
  ITP_ENABLED = 'ITP_ENABLED',
  NETWORK_VIEW_MARKET_STATISTICS_ENABLED = 'NETWORK_VIEW_MARKET_STATISTICS_ENABLED',
  OMIT_COMPETITOR_DETAILS = 'OMIT_COMPETITOR_DETAILS',
  ITP_REBRANDING_ENABLED = 'ITP_REBRANDING_ENABLED',
  RFQ_ENGAGEMENT = 'RFQ_ENGAGEMENT',
  TENDER_HANDOVER = 'TENDER_HANDOVER',
  TENDER_MARKET_VIEW = 'TENDER_MARKET_VIEW',
  TENDER_RETENDER = 'TENDER_RETENDER',
  TENDER_SUGGESTION_ENABLED = 'TENDER_SUGGESTION_ENABLED',
  TENDER_INVITE_MAX = 'TENDER_INVITE_MAX',
  PROJECT_VIEW_TENDERERS = 'PROJECT_VIEW_TENDERERS',
  ACTION_RFQ_ENABLED = 'ACTION_RFQ_ENABLED',
  ADVANCED_PROJECT_DETAILS = 'ADVANCED_PROJECT_DETAILS',
  ADVANCED_SEARCH = 'ADVANCED_SEARCH',
  CONSULTANT_DETAILS = 'CONSULTANT_DETAILS',
  PROJECT_COVER_SHEETS = 'PROJECT_COVER_SHEETS',
  ACCOUNT_USERS_RESTRICT = 'ACCOUNT_USERS_RESTRICT',
  SUBBIE_ADDONS = 'SUBBIE_ADDONS',
  SUBBIE_PROFILE = 'SUBBIE_PROFILE',
  TENDER_CALENDAR_SYNC = 'TENDER_CALENDAR_SYNC',
  TENDER_NOTICEBOARD_ENABLED = 'TENDER_NOTICEBOARD_ENABLED',
  TENDER_VIEW_MAX = 'TENDER_VIEW_MAX',
  /** @deprecated no longer in use, but still in the db */
  STAGE_FILES_ENABLED = 'STAGE_FILES_ENABLED',
  NETWORK_CONNECTIONS = 'NETWORK_CONNECTIONS',
  NETWORK_SEARCH = 'NETWORK_SEARCH',
  NETWORK_UNIFIED_PROFILE = 'NETWORK_UNIFIED_PROFILE',
  PROJECT_VIEW_AWARDED_ENABLED = 'PROJECT_VIEW_AWARDED_ENABLED',
  MORE_RESTRICTIVE_BUDGET_PAYWALL = 'MORE_RESTRICTIVE_BUDGET_PAYWALL',
  PROJECT_VIEW_VALUE_MAX = 'PROJECT_VIEW_VALUE_MAX',
  UNREDACT_FIFTY = 'UNREDACT_FIFTY',
  ADVANCED_SPEC_SEARCH = 'ADVANCED_SPEC_SEARCH',
  PRODUCT_INSIGHTS_REPORT = 'PRODUCT_INSIGHTS_REPORT',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COMPANY_PROFILE_ADVANCED = 'COMPANY_PROFILE_ADVANCED',
  CREDITS_LITE_TIER = 'CREDITS_LITE_TIER',
  /** @deprecated no longer in use */
  PROJECT_SPEC_SEARCH_MAX = 'PROJECT_SPEC_SEARCH_MAX',
  SPECIFINDER_SAVED_KEYWORDS = 'SPECIFINDER_SAVED_KEYWORDS',
  SPECIFINDER_KEYWORD_VARIATIONS = 'SPECIFINDER_KEYWORD_VARIATIONS',
  SPECIFINDER_ADVANCED_SEARCH = 'SPECIFINDER_ADVANCED_SEARCH',
  CONSULTANT_INSIGHTS = 'CONSULTANT_INSIGHTS',
  MARKET_INSIGHTS = 'MARKET_INSIGHTS',
  SPECIFICATION_INSIGHTS = 'SPECIFICATION_INSIGHTS',
  BUILDER_DIRECTORY = 'BUILDER_DIRECTORY',
  WATCHLIST_ASSIGN_PROJECT_TO_USER = 'WATCHLIST_ASSIGN_PROJECT_TO_USER',
  SSC_5_CREDIT = 'SSC_5_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_10_CREDIT = 'SSC_10_CREDIT',
  SSC_15_CREDIT = 'SSC_15_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_20_CREDIT = 'SSC_20_CREDIT',
  SSC_30_CREDIT = 'SSC_30_CREDIT',
  SSC_50_CREDIT = 'SSC_50_CREDIT',
  SSC_20_TOP_UP_CREDIT = 'SSC_20_TOP_UP_CREDIT',
  SSC_50_TOP_UP_CREDIT = 'SSC_50_TOP_UP_CREDIT',
  SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT = 'SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT',
  ENTERPRISE_SSO = 'ENTERPRISE_SSO',
  BUILDER_FIXED_PRICE_QUOTES = 'BUILDER_FIXED_PRICE_QUOTES',
  SUBBIE_SEE_ALL_TENDERING_BUILDERS = 'SUBBIE_SEE_ALL_TENDERING_BUILDERS',
  LEADS_MANAGEMENT = 'LEADS_MANAGEMENT',
  SPECIFINDER_CAN_SEE_UPGRADE_HOOK = 'SPECIFINDER_CAN_SEE_UPGRADE_HOOK',
  QUOTE_VIEWED_BY_BUILDER = 'QUOTE_VIEWED_BY_BUILDER',
  QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK = 'QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK'
}

export enum CreditCardCompany {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX'
}

export enum InvoiceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  OUTSTANDING = 'OUTSTANDING',
  CANCELLED = 'CANCELLED',
  POSTED = 'POSTED',
  PAYMENT_DUE = 'PAYMENT_DUE',
  NOT_PAID = 'NOT_PAID',
  VOIDED = 'VOIDED'
}

export interface SearchProjectsInput {
  query: Scalars['String']['input'];
}

export enum AddressBookDataSource {
  IMPORT = 'IMPORT',
  BUILDER_CREATED = 'BUILDER_CREATED',
  RFQ = 'RFQ',
  PROFILE = 'PROFILE',
  ITP = 'ITP',
  SHADOW_PROFILE = 'SHADOW_PROFILE',
  NETWORK_INVITATION_PROFILE = 'NETWORK_INVITATION_PROFILE',
  BUILDER_AND_PROFILE = 'BUILDER_AND_PROFILE'
}

export enum ContactType {
  ESTIMATING = 'ESTIMATING',
  CONSTRUCTION = 'CONSTRUCTION'
}

export enum CriticalStatus {
  NO_CHANGE = 'NO_CHANGE',
  IS_CRITICAL = 'IS_CRITICAL'
}

export enum LettingScheduleCustomColumnIdentifier {
  CUSTOM_COLUMN_1 = 'CUSTOM_COLUMN_1',
  CUSTOM_COLUMN_2 = 'CUSTOM_COLUMN_2'
}

export enum LettingSchedulePriority {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export enum AccountFileType {
  QUOTE_ADDITIONAL = 'QUOTE_ADDITIONAL',
  INSURANCE = 'INSURANCE',
  CAPABILITY_STATEMENT = 'CAPABILITY_STATEMENT'
}

export enum QuoteLogType {
  ADDED = 'ADDED',
  COMPLETED = 'COMPLETED',
  CREATED_FROM_RFQ = 'CREATED_FROM_RFQ',
  DELETED = 'DELETED',
  DOWNLOADED = 'DOWNLOADED',
  DOWNLOADED_ANON = 'DOWNLOADED_ANON',
  DOWNLOADED_BULK_PACKAGE = 'DOWNLOADED_BULK_PACKAGE',
  DOWNLOADED_BULK_UNCAT = 'DOWNLOADED_BULK_UNCAT',
  DOWNLOADED_BULK_STAGE = 'DOWNLOADED_BULK_STAGE',
  FILE_DOWNLOADED = 'FILE_DOWNLOADED',
  FILE_DOWNLOADED_ANON = 'FILE_DOWNLOADED_ANON',
  PACKAGE_ASSIGNED = 'PACKAGE_ASSIGNED',
  REVISED = 'REVISED',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_UNCAT = 'SUBMITTED_UNCAT',
  VIEWED = 'VIEWED'
}

export enum RfqQuotingStatus {
  DECLINED = 'DECLINED',
  QUOTED = 'QUOTED',
  QUOTING = 'QUOTING'
}

export enum ProjectStatus {
  OPEN = 'OPEN',
  AWARDED = 'AWARDED',
  CLOSED = 'CLOSED'
}

export enum StageCategory {
  AGED_CARE = 'AGED_CARE',
  CIVIL = 'CIVIL',
  COMMERCIAL = 'COMMERCIAL',
  CORRECTIONS = 'CORRECTIONS',
  DEFENCE = 'DEFENCE',
  EDUCATION = 'EDUCATION',
  FIT_OUT = 'FIT_OUT',
  GOVERNMENT = 'GOVERNMENT',
  INDUSTRIAL_HEAVY = 'INDUSTRIAL_HEAVY',
  INDUSTRIAL_LIGHT = 'INDUSTRIAL_LIGHT',
  MEDICAL = 'MEDICAL',
  RECREATION = 'RECREATION',
  REFURBISHMENT = 'REFURBISHMENT',
  RESIDENTIAL = 'RESIDENTIAL',
  RETAIL = 'RETAIL',
  OTHER = 'OTHER',
  MIXED_USE = 'MIXED_USE',
  HOSPITALITY = 'HOSPITALITY',
  TOURISM = 'TOURISM',
  ENTERTAINMENT = 'ENTERTAINMENT',
  DATA_CENTRE = 'DATA_CENTRE',
  HEALTH = 'HEALTH',
  PHARMACEUTICAL = 'PHARMACEUTICAL'
}

export enum StageType {
  TENDER = 'TENDER',
  PROCUREMENT = 'PROCUREMENT'
}

export enum ConsultantDiscipline {
  ARCHITECT = 'ARCHITECT',
  ARCHITECT_INTERIOR = 'ARCHITECT_INTERIOR',
  ARCHITECT_LANDSCAPE = 'ARCHITECT_LANDSCAPE',
  ENGINEER_ELECTRICAL = 'ENGINEER_ELECTRICAL',
  ENGINEER_FIRE = 'ENGINEER_FIRE',
  ENGINEER_HYDRO = 'ENGINEER_HYDRO',
  ENGINEER_MECH = 'ENGINEER_MECH',
  ENGINEER_STRUCTURAL = 'ENGINEER_STRUCTURAL',
  ENGINEER_CIVIL = 'ENGINEER_CIVIL',
  ENGINEER_ACOUSTIC = 'ENGINEER_ACOUSTIC',
  CONSULTANT_FIRE = 'CONSULTANT_FIRE',
  ENGINEER_THERMAL = 'ENGINEER_THERMAL',
  CONSULTANT_TECHNOLOGY = 'CONSULTANT_TECHNOLOGY',
  CONSULTANT_SUSTAINABILITY = 'CONSULTANT_SUSTAINABILITY',
  CONSULTANT_LIGHTING = 'CONSULTANT_LIGHTING'
}

export enum ProjectRedactedReason {
  AWARDED = 'AWARDED',
  BUDGET = 'BUDGET',
  CREDIT_EXPERIMENT = 'CREDIT_EXPERIMENT',
  FIFTY_PERCENT = 'FIFTY_PERCENT',
  SINGLE_TENDERER = 'SINGLE_TENDERER'
}

export enum ProfileViewLogSource {
  ADDRESSBOOK = 'ADDRESSBOOK',
  DIRECTORY = 'DIRECTORY',
  PAGE = 'PAGE',
  QUOTE = 'QUOTE',
  RFQ = 'RFQ'
}

export enum ExperimentFilter {
  USER = 'USER'
}

export enum ExperimentName {
  BUILDER_VISUAL_DOC_DIFFS = 'BUILDER_VISUAL_DOC_DIFFS',
  BUILDER_MENTION_TEAMMATES_IN_NOTES = 'BUILDER_MENTION_TEAMMATES_IN_NOTES',
  BUILDER_DOC_RENAME_WIZARD = 'BUILDER_DOC_RENAME_WIZARD',
  BUILDER_SHAREPOINT_INTEGRATION = 'BUILDER_SHAREPOINT_INTEGRATION',
  SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE = 'SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE',
  SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY = 'SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY',
  NEW_IN_SUBBIE_CREDIT_EXPERIMENT = 'NEW_IN_SUBBIE_CREDIT_EXPERIMENT',
  SUBBIE_ACTIVITY_FEED = 'SUBBIE_ACTIVITY_FEED',
  SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS = 'SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS',
  SPECIFINDER_TRIAL_WITH_ONE_KEYWORD = 'SPECIFINDER_TRIAL_WITH_ONE_KEYWORD',
  SUBBIE_SPECIFINDER_INVITATIONS = 'SUBBIE_SPECIFINDER_INVITATIONS',
  SUPPLIER_SSC_TRIAL = 'SUPPLIER_SSC_TRIAL',
  SUBBIE_CORE_TRADES = 'SUBBIE_CORE_TRADES',
  BLD_REQUEST_PROFILE_UPDATE = 'BLD_REQUEST_PROFILE_UPDATE',
  ITP_OTP_AUTHENTICATOR = 'ITP_OTP_AUTHENTICATOR',
  /** @deprecated internal */
  SUBBIE_TEST = 'SUBBIE_TEST',
  /** @deprecated internal */
  BUILDER_TEST = 'BUILDER_TEST',
  SUPPLIER_FIFTY_REDACTED = 'SUPPLIER_FIFTY_REDACTED',
  SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API = 'SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API',
  PPT_CHARGEBEE_FREE_TO_PAID_FLOW = 'PPT_CHARGEBEE_FREE_TO_PAID_FLOW',
  ADOPT_LITE_TO_ESSENTIAL_UPGRADE = 'ADOPT_LITE_TO_ESSENTIAL_UPGRADE',
  PRISM_CHARGEBEE_RETENTION_MODULE = 'PRISM_CHARGEBEE_RETENTION_MODULE',
  /** @deprecated no longer in use */
  CHARGEBEE_CHECKOUT_EXPERIENCE = 'CHARGEBEE_CHECKOUT_EXPERIENCE',
  SUBBIE_PRICING_TREATMENT_1 = 'SUBBIE_PRICING_TREATMENT_1',
  SUBBIE_PRICING_TREATMENT_2 = 'SUBBIE_PRICING_TREATMENT_2',
  SUPPLIER_PRICING_TREATMENT_1 = 'SUPPLIER_PRICING_TREATMENT_1',
  SUPPLIER_PRICING_TREATMENT_2 = 'SUPPLIER_PRICING_TREATMENT_2',
  SUBBIE_ACCOUNT_ONBOARDING_FORM = 'SUBBIE_ACCOUNT_ONBOARDING_FORM',
  SUBBIE_NETWORK_ENROLMENT_FORM = 'SUBBIE_NETWORK_ENROLMENT_FORM',
  DISCOVER_LEADS_MGT = 'DISCOVER_LEADS_MGT',
  DISCOVER_LEADS_VIEW_OTHER_BUILDERS = 'DISCOVER_LEADS_VIEW_OTHER_BUILDERS',
  DISCOVER_LEADS_SPECIFINDER = 'DISCOVER_LEADS_SPECIFINDER',
  DISCOVER_LEADS_VIEWED_QUOTE = 'DISCOVER_LEADS_VIEWED_QUOTE',
  DISCOVER_QUOTES_VIEWED_QUOTE = 'DISCOVER_QUOTES_VIEWED_QUOTE',
  DISCOVER_SUNSET_INVITATIONS_BANNER = 'DISCOVER_SUNSET_INVITATIONS_BANNER',
  CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW = 'CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW',
  BUILDER_REDACTED_SUBBIE_PROFILE = 'BUILDER_REDACTED_SUBBIE_PROFILE',
  FUSION_SUBBIE_CHAS_TRIAL = 'FUSION_SUBBIE_CHAS_TRIAL'
}

export enum WorkforceSizeEnum {
  RANGE_0_5 = 'RANGE_0_5',
  RANGE_6_20 = 'RANGE_6_20',
  RANGE_21_50 = 'RANGE_21_50',
  RANGE_51_250 = 'RANGE_51_250',
  RANGE_251_PLUS = 'RANGE_251_PLUS',
  RANGE_250_PLUS = 'RANGE_250_PLUS'
}

export enum ContractSizeEnum {
  SIZE_10K = 'SIZE_10K',
  SIZE_50K = 'SIZE_50K',
  SIZE_100K = 'SIZE_100K',
  SIZE_250K = 'SIZE_250K',
  SIZE_500K = 'SIZE_500K',
  SIZE_1M = 'SIZE_1M',
  SIZE_5M = 'SIZE_5M',
  SIZE_10M = 'SIZE_10M',
  SIZE_25M = 'SIZE_25M',
  SIZE_50M_PLUS = 'SIZE_50M_PLUS'
}

export enum BusinessEntityType {
  REGISTERED_BUSINESS = 'REGISTERED_BUSINESS',
  SOLE_TRADER = 'SOLE_TRADER'
}

export enum ServiceAreaType {
  RADIAL = 'RADIAL',
  BOUNDARY = 'BOUNDARY'
}

export enum ServiceAreaBoundaryType {
  COUNTRY = 'COUNTRY',
  STATE = 'STATE'
}

export enum QualificationSource {
  CHAS = 'CHAS'
}

export enum QualificationType {
  COMPLIANCE = 'COMPLIANCE',
  MEMBERSHIP = 'MEMBERSHIP'
}

export enum QualificationLevel {
  ELITE = 'ELITE',
  ADVANCED = 'ADVANCED',
  STANDARD = 'STANDARD',
  FOUNDATION = 'FOUNDATION',
  VERIFIED_SUPPLIER = 'VERIFIED_SUPPLIER',
  COMMON_ASSESSMENT_STANDARD = 'COMMON_ASSESSMENT_STANDARD'
}

export enum QualificationStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE'
}

export interface UniversalProfileInput {
  profileId?: InputMaybe<Scalars['ID']['input']>;
  accountId?: InputMaybe<Scalars['ID']['input']>;
  addressbookCompanyId?: InputMaybe<Scalars['ID']['input']>;
}

export enum Source {
  ADDRESS_BOOK = 'ADDRESS_BOOK',
  SUBBIE_DIRECTORY = 'SUBBIE_DIRECTORY'
}

export enum BusinessIdentityNumberErrorCodes {
  COMPANY_IDENTIFIER_NOT_FOUND = 'COMPANY_IDENTIFIER_NOT_FOUND',
  NOT_SUPPORTED_COUNTRY = 'NOT_SUPPORTED_COUNTRY',
  TECHNICAL_ISSUE = 'TECHNICAL_ISSUE'
}

export interface SearchMatchingProfilesInput {
  profileId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  accountId?: InputMaybe<Scalars['String']['input']>;
}

export interface SearchProfilesAndCompaniesInput {
  resultType: ResultType;
  query?: InputMaybe<Scalars['String']['input']>;
  /** Find network companies whose default office is within {{DISTANCE}} of {{LOCATION}}. Avoid using with `serviceLocation` */
  distance?: InputMaybe<DistanceFromInput>;
  /** Find network companies whose default office services the {{LOCATION}}. Avoid using with `distance` */
  serviceLocation?: InputMaybe<LocationInput>;
  projectCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  stockTradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contractSizeMin?: InputMaybe<ContractSizeMinEnum>;
  contractSizeMax?: InputMaybe<ContractSizeMaxEnum>;
  workforceSize?: InputMaybe<WorkforceSizeEnum>;
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  connectionType?: InputMaybe<ConnectionType>;
  /** Defaults to build-in multi-factor ranking */
  sorting?: InputMaybe<SortProfilesInput>;
  /** Defaults to 200 results, no pagination */
  pagination?: InputMaybe<PaginationInput>;
}

export enum ResultType {
  PROFILE = 'PROFILE',
  AB_COMPANY = 'AB_COMPANY',
  BOTH = 'BOTH'
}

export interface DistanceFromInput {
  distance: Scalars['Int']['input'];
  location: LocationInput;
}

export interface LocationInput {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
}

export enum ContractSizeMinEnum {
  SIZE_0 = 'SIZE_0',
  SIZE_10k = 'SIZE_10k',
  SIZE_50k = 'SIZE_50k',
  SIZE_100k = 'SIZE_100k',
  SIZE_250k = 'SIZE_250k',
  SIZE_500k = 'SIZE_500k',
  SIZE_1M = 'SIZE_1M',
  SIZE_5M = 'SIZE_5M',
  SIZE_10M = 'SIZE_10M',
  SIZE_25M = 'SIZE_25M',
  SIZE_50M_PLUS = 'SIZE_50M_PLUS'
}

export enum ContractSizeMaxEnum {
  SIZE_10k = 'SIZE_10k',
  SIZE_50k = 'SIZE_50k',
  SIZE_100k = 'SIZE_100k',
  SIZE_250k = 'SIZE_250k',
  SIZE_500k = 'SIZE_500k',
  SIZE_1M = 'SIZE_1M',
  SIZE_5M = 'SIZE_5M',
  SIZE_10M = 'SIZE_10M',
  SIZE_25M = 'SIZE_25M',
  SIZE_50M_PLUS = 'SIZE_50M_PLUS'
}

export enum ConnectionType {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED'
}

export interface SortProfilesInput {
  sortBy?: InputMaybe<SortField>;
  /** N.b. when sorting by distance, DESC is closest to furthest */
  sortDirection?: InputMaybe<SortDirection>;
  /** Location must be supplied if sorting by SortField.DISTANCE */
  location?: InputMaybe<LocationInput>;
}

export enum SortField {
  NAME = 'NAME',
  CONTRACT_SIZE_MIN = 'CONTRACT_SIZE_MIN',
  WORKFORCE_SIZE = 'WORKFORCE_SIZE',
  DISTANCE = 'DISTANCE',
  LAST_ACTIVE = 'LAST_ACTIVE'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface PaginationInput {
  page?: InputMaybe<Scalars['Int']['input']>;
  resultsPerPage?: InputMaybe<Scalars['Int']['input']>;
}

export interface ChangeOfficeDetailsInput {
  accountOfficeId: Scalars['EntityId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  address: ChangeOfficeDetailsAddressInput;
  website?: InputMaybe<Scalars['String']['input']>;
  serviceArea: ServiceAreaInput;
}

export interface ChangeOfficeDetailsAddressInput {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['EntityId']['input']>;
}

export interface ServiceAreaInput {
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated (clarity) use boundaryIds */
  stateIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  boundaryIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  countryId?: InputMaybe<Scalars['EntityId']['input']>;
}

export interface AddOfficeDetailsInput {
  phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  address: ChangeOfficeDetailsAddressInput;
  website?: InputMaybe<Scalars['String']['input']>;
  serviceArea: ServiceAreaInput;
}

export interface HistoricalProjectInput {
  name: Scalars['String']['input'];
  builderName: Scalars['String']['input'];
  state?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  completionDate: Scalars['DateTime']['input'];
  contractSize: Scalars['Int']['input'];
  category: StageCategory;
  linkedProjectId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateOfficeDetailsInput {
  accountOfficeId: Scalars['EntityId']['input'];
  address: ProfileAddressInput;
  serviceArea: ServiceAreaInput;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileAddressInput {
  address1: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  suburb?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['EntityId']['input']>;
}

export interface UpdateProfileDetailsInput {
  name: Scalars['String']['input'];
  defaultOfficeAddress: ProfileAddressInput;
  businessEntityType?: InputMaybe<BusinessEntityType>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  primaryContactId: Scalars['EntityId']['input'];
}

export interface UpdateCompanyCapabilitiesInput {
  stockTradeIds: Array<Scalars['EntityId']['input']>;
  coreTradeIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  contractSizeMin?: InputMaybe<ContractSizeEnum>;
  contractSizeMax?: InputMaybe<ContractSizeEnum>;
  serviceArea: OfficeServiceAreaInput;
}

export interface OfficeServiceAreaInput {
  radius?: InputMaybe<Scalars['Int']['input']>;
  boundaryIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  countryId?: InputMaybe<Scalars['EntityId']['input']>;
}

export interface UpdateDefaultCompanyOfficeInput {
  companyId: Scalars['ID']['input'];
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['ID']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['ID']['input']>;
}

export interface UpdateCompanyDetailsInput {
  companyId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  custom1?: InputMaybe<Scalars['String']['input']>;
  custom2?: InputMaybe<Scalars['String']['input']>;
  custom3?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateCompanyPhoneInput {
  companyId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface SetOrUpdateUserSettingInput {
  settingName: UserSettingName;
  unset: Scalars['Boolean']['input'];
  stringValue?: InputMaybe<Scalars['String']['input']>;
  dateTimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
}

export enum UserSettingName {
  SUB_TRADES_ONBOARDING_MODAL_LAST_DISMISSED = 'SUB_TRADES_ONBOARDING_MODAL_LAST_DISMISSED',
  ATTR_ADD_PROJECT_HISTORY_BANNER_DISMISSED = 'ATTR_ADD_PROJECT_HISTORY_BANNER_DISMISSED'
}
