import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonVariant,
  Col,
  FilterIcon,
  LoadingSpinner,
  Row,
  SkeletonLoader,
  useDrawer,
} from '@estimateone/frontend-components';
import { captureException } from '@sentry/browser';
import { getLocationForPostcode } from '@builder/common/SubbieNetwork/utils/searchArea/google';
import CompaniesResults from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults';
import { FullSetOnlyAlert } from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults/FullSetOnlyAlert';
import { ConfirmInvitesModal } from '@builder/features/SubbieNetworkInvitations/components/ConfirmInvitesModal';
import { FilterChips } from '@builder/features/SubbieNetworkInvitations/components/FilterChips';
import FilterDrawer from '@builder/features/SubbieNetworkInvitations/components/FilterDrawer';
import { placePredictionsDefaultTypes } from '@builder/features/SubbieNetworkInvitations/components/FilterDrawer/PlaceSuggestingSelect';
import { InvitesErrorModal } from '@builder/features/SubbieNetworkInvitations/components/InvitesProgressModal/InvitesErrorModal/InvitesErrorModal';
import { NetworkInvitationsHelpBanner } from '@builder/features/SubbieNetworkInvitations/components/NetworkInvitationsHelpBanner';
import PackageActionsBar from '@builder/features/SubbieNetworkInvitations/components/PackageActionsBar/PackageActionsBar';
import { PackageSelectionSidebar } from '@builder/features/SubbieNetworkInvitations/components/PackageSelectionSidebar';
import TradeFilter from '@builder/features/SubbieNetworkInvitations/components/TradeFilter';
import {
  getTradeOptionsFromStageTradeList,
  isPackageEmpty,
  matchPackageToTrade,
} from '@builder/features/SubbieNetworkInvitations/utils';
import { InvitesProgressModal } from '../components/InvitesProgressModal/InvitesProgressModal';
import { useFlashMessage } from '@shared/Util';
import { pluralise } from '@shared/Util/pluralise';
import {
  useAccountPrimaryCountryId,
  useGetDistanceLabel,
} from '@builder/context/SubbieNetworkFilterDataProvider/hooks';
import { SelectedContactsContext } from '@builder/features/SubbieNetworkInvitations/context/SelectedContactsProvider';
import {
  DISTANCE_OPTION_MAX,
  useSearchNetworkCompanies,
} from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults/hooks';
import {
  useCurrentUser,
  useGetStageForNetworkInvites,
  useInviteContactsToPackage,
} from '@builder/features/SubbieNetworkInvitations/hooks';
import { Country } from '@ascension/enums';
import { WorkforceSizeEnum } from '@ascension/_gqltypes/builder.generated';
import { ID } from '@builder/common/SubbieNetwork/types';
import {
  ContractSize,
  LocationOption,
  SearchAreaOptions,
} from '@builder/common/SubbieNetwork/utils/filters/types';
import { Package } from '@builder/features/SubbieNetworkInvitations/types';
import styles from './styles.module.scss';

type UnifiedInvitesProps = {
  stageId: number;
  isConfirmInvitesModalOpen: boolean;
  setIsConfirmInvitesModalOpen: (isOpen: boolean) => void;
};

export const UnifiedInvites = ({
  stageId,
  isConfirmInvitesModalOpen,
  setIsConfirmInvitesModalOpen,
}: UnifiedInvitesProps) => {
  const { stage, loading: loadingPackages } = useGetStageForNetworkInvites(stageId);
  const stageTradeOptions = getTradeOptionsFromStageTradeList(stage?.trades);
  const getPackageNameById = (packageId: ID): string =>
    stage?.activePackages.find((pkg) => pkg.id === packageId)?.title ?? '';

  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>(undefined);
  const [quoteDue, setQuoteDue] = useState<Date | null>(
    selectedPackage?.quotesDueAt ? new Date(selectedPackage.quotesDueAt) : null,
  );

  const { submit: submitInvitations, loading: requestingInvitations } =
    useInviteContactsToPackage();
  const [isInvitesProgressModalOpen, setIsInvitesProgressModalOpen] = useState(false);
  const [isInvitesErrorModalOpen, setIsInvitesErrorModalOpen] = useState(false);
  const [sentInvitationsCount, setSentInvitationsCount] = useState(0);
  const [sendInvitationsErrors, setSendInvitationsErrors] = useState<string[]>([]);
  const [packagesWithInvitationErrors, setPackagesWithInvitationErrors] = useState<ID[]>([]);

  const {
    selectedContacts,
    resetSelectedContacts,
    resetSelectedContactsExcludingPackages,
    totalNumberOfSelectedContacts,
    numberOfSelectedContactsForPackage,
  } = useContext(SelectedContactsContext);

  const DEFAULT_DISTANCE = DISTANCE_OPTION_MAX;
  const DEFAULT_DISTANCE_LABEL = useGetDistanceLabel(DEFAULT_DISTANCE);

  const { isOpen, onOpen, onClose } = useDrawer(false);
  const [tradeId, setTradeId] = useState<number>();
  const [categoryId, setCategoryId] = useState<number>();
  const [contractSize, setContractSize] = useState<ContractSize>({
    min: undefined,
    max: undefined,
  });
  const [workforceSize, setWorkforceSize] = useState<WorkforceSizeEnum>();
  const [searchArea, setSearchArea] = useState<SearchAreaOptions>('office-location');
  const [location, setLocation] = useState<LocationOption>();
  const [distance, setDistance] = useState<number | undefined>(DEFAULT_DISTANCE);
  const [distanceLabel, setDistanceLabel] = useState<string | undefined>(DEFAULT_DISTANCE_LABEL);
  const [showE1Network, setShowE1Network] = useState(false);
  const currentUser = useCurrentUser();
  const primaryCountryId = useAccountPrimaryCountryId();
  const { success: notifyInvitationSuccess } = useFlashMessage();

  const {
    data,
    loading: loadingCompanyResults,
    refetchNetworkResults,
  } = useSearchNetworkCompanies({
    tradeId,
    categoryId,
    location,
    contractSize,
    distance,
    searchArea,
    workforceSize,
    distanceLabel,
    showE1Network,
  });

  useEffect(() => {
    if (selectedPackage === undefined) {
      const firstNonEmptyPackage = stage?.activePackages.find((pkg) => !isPackageEmpty(pkg));

      setSelectedPackage(firstNonEmptyPackage);

      if (firstNonEmptyPackage && stage?.trades) {
        const initSelectedTrade = matchPackageToTrade(firstNonEmptyPackage.title, stage.trades);

        setTradeId(initSelectedTrade?.stockTrade?.id);
      }

      setQuoteDue(
        firstNonEmptyPackage?.quotesDueAt ? new Date(firstNonEmptyPackage.quotesDueAt) : null,
      );
    }
  }, [stage, selectedPackage]);

  useEffect(() => {
    if (selectedPackage && stage?.trades) {
      const selectedTrade = matchPackageToTrade(selectedPackage.title, stage?.trades);
      setTradeId(selectedTrade?.stockTrade?.id);
    }
  }, [selectedPackage, stage]);

  //  This block sets the project's address as the default location for searching.
  useEffect(() => {
    if (location === undefined && stage?.projectAddress.postcode != null && !loadingPackages) {
      getLocationForPostcode(
        stage.projectAddress.postcode,
        primaryCountryId ?? Country.COUNTRY_AUSTRALIA,
        placePredictionsDefaultTypes,
      )
        .then((loc) => setLocation(loc))
        .catch((e: Error) => captureException(e));
    }
  }, [loadingPackages, location, stage, primaryCountryId]);

  if (loadingPackages) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  const onReset = () => {
    setTradeId(undefined);
    setCategoryId(undefined);
    setContractSize({ min: undefined, max: undefined });
    setWorkforceSize(undefined);
    setSearchArea('office-location');
    setLocation(undefined); // see location useEffect above
    setDistance(undefined);
    setDistanceLabel(undefined);
  };

  const handleCloseInvitesProgressModal = () => {
    setIsInvitesProgressModalOpen(false);
    setSentInvitationsCount(0);
    resetSelectedContacts();
    notifyInvitationSuccess({
      title: `${totalNumberOfSelectedContacts()} ${pluralise('invite', totalNumberOfSelectedContacts())} successfully sent`,
    });
  };

  const handleCloseInvitesErrorModal = () => {
    setIsInvitesErrorModalOpen(false);
    setSentInvitationsCount(0);
    resetSelectedContactsExcludingPackages(packagesWithInvitationErrors);
    setPackagesWithInvitationErrors([]);
    setSendInvitationsErrors([]);
  };

  const sendInvitesByPackage = async (packageId: number): Promise<boolean> => {
    if (selectedContacts.size === 0 || quoteDue === null) {
      throw Error('Package invites');
    }

    const selectedE1NetworkCompanyContactIds: string[] = [];
    const selectedBuilderCompanyContactIds: string[] = [];

    const selectedContactsForPackage = selectedContacts.get(packageId);

    if (selectedContactsForPackage === undefined || selectedContactsForPackage.size === 0) {
      return false;
    }

    selectedContactsForPackage.forEach((companyContacts) => {
      switch (companyContacts?.companyType) {
        case 'E1NetworkCompany': {
          selectedE1NetworkCompanyContactIds.push(
            ...Array.from(companyContacts.contactIds.map(String)),
          );
          break;
        }
        case 'BuilderNetworkCompany':
        case 'ConnectedE1NetworkCompany':
        case 'ConnectedBuilderNetworkCompany': {
          selectedBuilderCompanyContactIds.push(
            ...Array.from(companyContacts.contactIds.map(String)),
          );
          break;
        }
        default: {
          throw new Error(`Unsupported company type: ${companyContacts?.companyType}`);
        }
      }
    });

    const { errors } = await submitInvitations({
      packageId,
      userIds: selectedE1NetworkCompanyContactIds,
      addressBookContactIds: selectedBuilderCompanyContactIds,
      quotesDueAt: quoteDue.toISOString(),
    });

    if (errors) {
      setSendInvitationsErrors((prevErrors) => [
        ...prevErrors,
        `Invites for package ${getPackageNameById(packageId)} failed to send: ${errors}`,
      ]);
      return false;
    }

    return true;
  };

  const handleInvites = async () => {
    setIsConfirmInvitesModalOpen(false);
    setIsInvitesProgressModalOpen(true);

    const requests = await Promise.all(
      Array.from(selectedContacts.keys()).map(async (packageId): Promise<[boolean, ID]> => {
        const success = await sendInvitesByPackage(Number(packageId));
        if (success) {
          setSentInvitationsCount(
            (prevCount) => prevCount + numberOfSelectedContactsForPackage(packageId),
          );
        }
        return [success, packageId];
      }),
    );

    const packagesWithErrors = requests
      .filter(([success]) => !success)
      .map(([, packageId]) => packageId);
    setPackagesWithInvitationErrors(packagesWithErrors);

    await refetchNetworkResults();

    if (packagesWithErrors.length === 0) {
      handleCloseInvitesProgressModal();
    } else {
      setIsInvitesProgressModalOpen(false);
      setIsInvitesErrorModalOpen(true);
    }
  };

  const packageSelectHandler = (pkg: Package) => {
    setSelectedPackage(pkg);
    setQuoteDue(pkg.quotesDueAt ? new Date(pkg.quotesDueAt) : null);
  };

  const isEveryNonFullSetPackageEmpty = stage?.activePackages
    .filter(({ fullSet }) => !fullSet)
    .every(isPackageEmpty);

  const hasOnlyFullSetPackage =
    (stage?.activePackages.length === 1 && stage?.activePackages[0].fullSet) ||
    isEveryNonFullSetPackageEmpty;

  return (
    <div className="wrapper">
      {currentUser?.id && (
        <NetworkInvitationsHelpBanner userId={currentUser?.id} stageId={stageId} />
      )}
      <Row>
        <Col span={3} excludeBottomGutter>
          <PackageSelectionSidebar
            packages={stage?.activePackages}
            numberOfSelectedContactsForPackage={numberOfSelectedContactsForPackage}
            selectedPackage={selectedPackage}
            onPackageSelect={packageSelectHandler}
          />
        </Col>
        <Col span={9} excludeBottomGutter>
          <PackageActionsBar
            quoteDueAt={quoteDue}
            setQuoteDueAt={setQuoteDue}
            packageTitle={selectedPackage?.title}
          />
          <div className={styles.filterSection}>
            <div>
              <TradeFilter
                stageTradeOptions={stageTradeOptions}
                tradeId={tradeId}
                onSelectTrade={setTradeId}
              />
            </div>
            <div className={styles.filterButtons}>
              <Button
                variant={ButtonVariant.Grey}
                onClick={onOpen}
                className={styles.buttonWithIcon}
                aria-label="All Filters"
              >
                <FilterIcon className={styles.filterIcon} />
                All Filters
              </Button>
              <Button variant={ButtonVariant.Transparent} onClick={onReset}>
                Reset
              </Button>
            </div>
            <div className={styles.chips}>
              <FilterChips
                onFilterChipClicked={onOpen}
                categoryId={categoryId}
                location={location}
                contractSize={contractSize}
                workforceSize={workforceSize}
                searchArea={searchArea}
                distanceLabel={distanceLabel}
                showE1NetworkResults={showE1Network}
                handleShowE1NetworkToggle={setShowE1Network}
              />
            </div>
          </div>
          {hasOnlyFullSetPackage && <FullSetOnlyAlert />}
          {loadingCompanyResults || selectedPackage === undefined ? (
            <SkeletonLoader count={5} height="16px" />
          ) : (
            <CompaniesResults
              networkCompanies={data?.results ?? null}
              currentPackageId={selectedPackage.id}
              invitedAddressBookContacts={
                stage?.activePackages
                  .find((p) => p.id === selectedPackage.id)
                  ?.rfqs.filter((rfq) => rfq.contact !== null)
                  .map((rfq) => rfq.contact!.id) ?? []
              }
              isTradeSelected={!!tradeId}
            />
          )}
        </Col>
      </Row>
      <FilterDrawer
        isOpen={isOpen}
        onClose={onClose}
        tradeId={tradeId}
        onSelectTrade={setTradeId}
        stageTradeOptions={stageTradeOptions}
        categoryId={categoryId}
        onSelectCategory={setCategoryId}
        location={location}
        onSetLocation={setLocation}
        contractSize={contractSize}
        onSelectContractSize={setContractSize}
        workforceSize={workforceSize}
        onSelectWorkforceSize={setWorkforceSize}
        searchArea={searchArea}
        onSelectSearchArea={setSearchArea}
        distance={distance}
        onSetDistance={setDistance}
        onSetDistanceLabel={setDistanceLabel}
      />
      <ConfirmInvitesModal
        isOpen={isConfirmInvitesModalOpen}
        inviteCount={totalNumberOfSelectedContacts()}
        onRequestClose={() => setIsConfirmInvitesModalOpen(false)}
        onSendInvites={handleInvites}
        isSending={requestingInvitations}
      />
      <InvitesProgressModal
        isOpen={isInvitesProgressModalOpen}
        sentInvitationsCount={sentInvitationsCount}
        totalInvitationsCount={totalNumberOfSelectedContacts()}
        onRequestClose={handleCloseInvitesProgressModal}
      />
      <InvitesErrorModal
        isOpen={isInvitesErrorModalOpen}
        sentInvitationsCount={sentInvitationsCount}
        totalInvitationsCount={totalNumberOfSelectedContacts()}
        onRequestClose={handleCloseInvitesErrorModal}
        errorMessages={sendInvitationsErrors}
      />
    </div>
  );
};
