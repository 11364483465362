import Routing from 'routing';
import { Button, ButtonVariant, Icon, IconName, Row } from '@estimateone/frontend-components';
import E1Request from '../../../../js/classes/E1Request';
import { EntityId } from '../../../../types';
import styles from './styles.scss';

const showMergeModal = async (from: EntityId, to: EntityId) => {
  const route = Routing.generate('app_addressbookcompany_mergepreviewdetails', {
    id: from,
    original: to,
  });
  return new E1Request(route).setShowLoadingModal().submit();
};

type CompanyActionsProps = {
  originalId: EntityId;
  duplicateId: EntityId;
  onDismiss: () => void;
  dismissText?: string;
};

const CompanyActions = ({
  originalId,
  duplicateId,
  onDismiss,
  dismissText = 'Ignore',
}: CompanyActionsProps) => (
  <div className={styles.companyActions}>
    <Row>
      <Button
        variant={ButtonVariant.Secondary}
        fullWidth
        onClick={() => showMergeModal(originalId, duplicateId)}
      >
        Preview merge
        <Icon name={IconName.ArrowRight} marginLeft="small" size="0.8em" />
      </Button>
    </Row>
    <Row>
      <Button
        variant={ButtonVariant.Secondary}
        fullWidth
        onClick={() => showMergeModal(duplicateId, originalId)}
      >
        <Icon name={IconName.ArrowLeft} marginRight="small" size="0.8em" />
        Preview merge
      </Button>
    </Row>
    <Row>
      <Button variant={ButtonVariant.RedOutline} fullWidth onClick={onDismiss}>
        {dismissText}
      </Button>
    </Row>
  </div>
);

export default CompanyActions;
