import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query GetCompany($id: EntityId!) {
    company(id: $id) {
      id
      name
      address {
        id
        fullAddress
      }
      phone
      activeContacts {
        id
        fullName
        email
        phone
      }
      trades {
        name
      }
    }
  }
`;
