import { useQuery } from '@apollo/client';
import { Card, CardVariant } from '@estimateone/frontend-components';
import LoadingSpinner from '@shared/LoadingSpinner';
import { Company } from '@builder/common/AddressBook/hooks/useSearchAddressBookCompanies';
import { GET_COMPANY } from './queries';
import {
  GetCompany,
  GetCompanyVariables,
  GetCompany_company_activeContacts as ActiveContact,
} from './types/GetCompany';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

/* see AddressBookCompanyDuplicateController.php */
export type DuplicateEntry = {
  id: EntityId;
  name: string;
  address: string | null;
  phone: string | null;
  contacts: {
    id: EntityId;
    fullName: string;
    email: string | null;
    phone: string | null;
  }[];
  trades: string[];
};

type CompanyHeaderProps = {
  id: EntityId;
  name: string;
  address: string | null;
  phone: string | null;
};

const CompanyHeader = ({ id, name, address, phone }: CompanyHeaderProps) => (
  <div className={styles.companyCardHeader}>
    <h5>
      <div>{name}</div>
      <div className={styles.companyCardHeaderId}>#{id}</div>
    </h5>
    <div className={styles.companyDetails}>
      <span>{address || '-'}</span>
      <span>{phone || '-'}</span>
    </div>
  </div>
);

type ContactCardDetailProps = { name: string; email: string | null; phone: string | null };

const ContactCardDetail = ({ name, email, phone }: ContactCardDetailProps) => (
  <li className={styles.contact}>
    <div className={styles.contactName} title={name}>
      <strong>{name}</strong>
    </div>
    <div className={styles.contactEmail} title={email || '-'}>
      {email || '-'}
    </div>
    <div className={styles.contactPhone} title={phone || '-'}>
      {phone || '-'}
    </div>
  </li>
);

type ContactDetailsProps = { contacts: Omit<ActiveContact, '__typename'>[] };

const ContactDetails = ({ contacts }: ContactDetailsProps) => (
  <ul className={styles.companyCardContacts}>
    {contacts.map(({ id, fullName, email, phone }) => (
      <ContactCardDetail key={id} name={fullName} email={email} phone={phone} />
    ))}
  </ul>
);

const CompanyCardContent = ({
  id,
  address,
  name,
  phone,
  contacts,
}: CompanyHeaderProps & ContactDetailsProps) => (
  <Card variant={CardVariant.Grey}>
    <Card.Body isWhite>
      <CompanyHeader id={id} name={name} address={address} phone={phone} />
      <ContactDetails contacts={contacts} />
    </Card.Body>
  </Card>
);

const isDuplicateEntry = (company: DuplicateEntry | Company): company is DuplicateEntry =>
  (company as DuplicateEntry).address !== undefined;

const CompanyCard = ({ company }: { company: DuplicateEntry | Company }) => {
  // AddressBookDuplicates/index.js request already returns companies in correct format so don't need to make graphql request
  const existingAddress = isDuplicateEntry(company);

  const { data, error, loading } = useQuery<GetCompany, GetCompanyVariables>(GET_COMPANY, {
    variables: { id: company.id },
    skip: existingAddress,
  });

  if (isDuplicateEntry(company)) {
    const { id, name, address, phone, contacts } = company;

    return (
      <CompanyCardContent id={id} name={name} address={address} phone={phone} contacts={contacts} />
    );
  }

  if (error) throw Error(error.message);

  if (loading) return <LoadingSpinner size="medium" center />;

  if (!data) throw Error('Failed to fetch company details');

  const { id, name, address, phone, activeContacts } = data.company;

  return (
    <CompanyCardContent
      id={id}
      name={name}
      address={address?.fullAddress || null}
      phone={phone}
      contacts={activeContacts}
    />
  );
};

export default CompanyCard;
