/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-assign */
/* eslint-disable no-param-reassign */

import $ from 'jquery';
import Routing from 'routing';
import { filter, find, indexOf, pluck } from 'underscore';
import E1Request, { E1Response } from '../classes/E1Request';
import Throttler from '../classes/Throttler';
import downloadFromPost from '../utils/download_from_post';
import DocumentDownload from './document-download';
import BulkEditModal from './documents-bulk-edit';
import SupersedeModal from './documents-supersede';
import { activateLoadingButton, deactivateLoadingButton } from './loading_button';
import { EntityId } from '@ascension/types';

type DocumentItem = {
  id: number;
  fileName: string;
  title: string;
  directoryName: string | null;
  fileSize: number;
  drawingId: string;
  revision: string;
  addendum: { id: EntityId; name: string; createdAt: string };
  supersededDocument: { id: EntityId; title: string };
  pendingRevision: { id: EntityId; newDirectoryName: string };
  selected?: boolean;
  isNew?: boolean;
  currentDirectoryName?: string;
  DT_RowId?: string;
  syncStatus?: SyncStatus;
};

enum SyncStatus {
  New = 'New',
  Revised = 'Revised',
  Confirmed = 'Confirmed',
}

type DocumentsResponse = { data: DocumentItem[] } & E1Response;

export default class DocumentList {
  stageId: EntityId;
  addendumId: EntityId;
  $target: JQuery<HTMLElement>;
  $container: JQuery<HTMLElement>;
  $docCount: JQuery<HTMLElement>;
  $emptyPlaceholder: JQuery<HTMLElement>;
  $controlPanel: JQuery<HTMLElement>;
  tableShowing: boolean;
  data: DocumentItem[];
  newFiles: EntityId[]; // Array of ids set from documents-upload.js
  newCol?: number;
  fetchRequest: E1Request<E1Response>;
  $folderUploadButton: JQuery<HTMLElement>;
  initFunc: (list: DocumentList) => void;
  fetchUrl: string;
  throttler: Throttler;
  requiresInit: boolean;
  table!: DataTables.Api;

  constructor(
    stageId: EntityId,
    addendumId: EntityId,
    $target: JQuery<HTMLElement>,
    initFunc: (list: DocumentList) => void,
    fetchUrl: string,
  ) {
    this.stageId = stageId;
    this.addendumId = addendumId;
    this.$target = $target;
    this.$container = $target.closest('.doc-container');
    this.$docCount = this.$container.find('.docCount');
    this.$emptyPlaceholder = this.$container.find('.empty-text-placeholder');
    this.$controlPanel = this.$container.find('.table-control-panel-container');
    this.tableShowing = true;
    this.data = [];
    this.newFiles = [];
    this.newCol = undefined;
    this.fetchRequest = new E1Request(fetchUrl, 'GET');
    this.$folderUploadButton = $('a#path-file-uploader');
    this.initFunc = initFunc;
    this.fetchUrl = fetchUrl;
    this.throttler = new Throttler();
    this.requiresInit = true;

    this.fetch();

    this.$target.closest('.section').on('keyup', '.custom-table-search', (e) => {
      const $searchInput: JQuery<HTMLInputElement> = $(e.currentTarget);
      if (this.table !== null) {
        const inputVal = $searchInput.val();
        if (inputVal) {
          this.table.search(inputVal.toString()).draw();
        }
      }
    });

    this.$target.on('data-updated', () => {
      if (this.$docCount.length > 0) {
        this.$docCount.text(this.data.length);
      }
    });
  }

  /**
   * This is used to determine whether the 'upload to folder' button should be shown, as if the browser is ie9 or lower, uploadify
   * will be used for file uploads, and it does not support the upload to folder functionality. Ideally, we would pass through
   * the upload module, but you can't always get what you want!
   */
  canUploadToFolder() {
    return this.$folderUploadButton.length > 0 && this.$folderUploadButton.hasClass('activated');
  }

  init() {
    if (this.$controlPanel.length > 0) {
      this.$controlPanel.prependTo('.bottom-bar');
      this.$controlPanel.removeClass('hide');
    }

    this.toggleTableDisplay(true);
    this.$target.closest('.loading-container').addClass('has-loaded');
    this.$target.on('draw.dt', () => {
      this.updatePagination();
    });
    this.updatePagination();
    this.$target.trigger('data-updated');

    this.updateSelectedTotals();

    this.$target.on('change', '.select-checkbox', ({ currentTarget }) => {
      const checked = $(currentTarget).is(':checked');
      const $row = $(currentTarget).closest('tr');
      const row = $row.get(0);
      const rowData = this.table.row(row).data() as DocumentItem;

      rowData.selected = checked;
      this.table.row(row).data(rowData);

      this.data = this.table.rows().data().toArray();

      this.$target.trigger('data-selected-updated');
      const group = $row.attr('data-group');
      if (group && rowData.currentDirectoryName) {
        this.updateFolderSelect(group, rowData.currentDirectoryName);
      }
      this.updateSelectedTotals();
    });

    this.$target.on('change', '.select-all-rows', ({ currentTarget }) => {
      const checked = $(currentTarget).is(':checked');

      this.data.forEach((row) => {
        Object.assign(row, { selected: checked });
      });

      if (window.analyticsService) {
        window.analyticsService.addInteractEvent({
          action: 'ToggleSelectAllDocuments',
          selectedDocsCount: this.data.filter((r) => r.selected).length,
          isDeselect: !checked,
        });
      }

      this.$target.trigger('data-selected-updated');
      this.updateTable();
      this.updateSelectedTotals();
      DocumentList.updateExportOptions(checked);
    });

    this.$target.on('click', '.supersedeLink', async ({ currentTarget }) => {
      const id = $(currentTarget).attr('data-id')!;
      const found = find(this.data, (f) => f.id.toString() === id);
      if (found) {
        const url = Routing.generate('app_stagedocument_supersedemodal', {
          id: this.stageId,
          docId: id,
          addendumId: this.addendumId,
        });
        const request = new E1Request(url, 'GET');
        request.show_loading_modal = true;
        await request.submit();
        // eslint-disable-next-line no-new
        new SupersedeModal($('.supersede-document-form'), this.stageId, id, this);
      }
    });

    this.$target.on('click', '.removeSupersedeLink', async ({ currentTarget }) => {
      const id = $(currentTarget).attr('data-id');
      const url = Routing.generate('app_stagedocument_removesupersede', {
        id: this.stageId,
        docId: id,
      });
      const request = new E1Request(url, 'POST');
      request.extraCallback = () => {
        this.updateTable(true);
        this.$target.trigger('data-supersede-removed');
      };
      await request.submit();
    });

    const containers = [this.$container, this.$controlPanel];

    containers.forEach(async ($container) => {
      $container.on('click', '.delete-docs', async ({ currentTarget }) => {
        const $button = $(currentTarget);
        activateLoadingButton($button);
        const docIds = this.getSelectedIds();
        const method = Routing.generate('app_stagedocument_deletemodal', { id: this.stageId });
        const data = {
          docId: docIds,
          addendum: this.addendumId,
        };
        const request = new E1Request(method, 'POST', data);
        deactivateLoadingButton($button);
        request.show_loading_modal = true;
        await request.submit();
      });

      $container.on('click', '.move-docs-trigger', async ({ currentTarget }) => {
        const $button = $(currentTarget);
        activateLoadingButton($button);
        const docIds = this.getSelectedIds();
        const method = Routing.generate('app_stagedocument_movemodal', {
          id: this.stageId,
          addendumId: this.addendumId,
        });
        const data = {
          docId: docIds,
        };

        const request = new E1Request(method, 'POST', data);
        deactivateLoadingButton($button);
        request.show_loading_modal = true;
        await request.submit();
      });

      $container.on('click', '.download-docs-trigger', async ({ currentTarget }) => {
        const $button = $(currentTarget);
        activateLoadingButton($button);
        const docIds = this.getSelectedIds();

        const downloadRequest = new DocumentDownload(this.stageId, docIds);
        await downloadRequest.submit();
        deactivateLoadingButton($button);
      });

      $container.on('click', '.edit-docs-trigger', async ({ currentTarget }) => {
        const $button = $(currentTarget);
        activateLoadingButton($button);
        const docIds = this.getSelectedIds();

        const data = {
          doc_ids: docIds.join(),
        };

        const route = Routing.generate('app_stagedocument_bulkedit', {
          id: this.stageId,
        });

        const request = new E1Request(route, 'GET', data);
        request.extraCallback = () => {
          new BulkEditModal($('.bulk-edit-documents-modal'), this.stageId);
        };

        deactivateLoadingButton($button);
        request.show_loading_modal = true;
        await request.submit();
      });

      $container.on('click', '.transmit-docs-trigger', async ({ currentTarget }) => {
        const $button = $(currentTarget);
        activateLoadingButton($button);
        const docIds = this.getSelectedIds();

        const data = {
          docId: docIds,
        };

        const route = Routing.generate('app_constructionstagedocument_transmitmodal', {
          id: this.stageId,
        });
        const request = new E1Request(route, 'POST', data);
        deactivateLoadingButton($button);
        request.show_loading_modal = true;
        await request.submit();
      });

      $container.on('click', '.export-docs-trigger', () => {
        const docIds = this.getSelectedIds();

        const data = {
          docIds: docIds.join(','),
        };

        const route = Routing.generate('app_stagedocument_exportregister', {
          id: this.stageId,
        });

        downloadFromPost(route, data);
      });

      $container.on('click', '.docs-rename-wizard-trigger', () =>
        document.dispatchEvent(
          new CustomEvent('rename-wizard-open', { detail: { docIds: this.getSelectedIds() } }),
        ),
      );
    });

    // eslint-disable-next-line no-new
    new $.fn.dataTable.FixedHeader(this.table, {});
    this.requiresInit = false;
  }

  deselectAll() {
    $.each(this.data, (i, row) => {
      row.selected = false;
    });
  }

  updatePagination() {
    const pagingInfo = this.table.page.info();
    if (pagingInfo.pages <= 1) {
      this.$container.find('.dataTables_paginate').hide();
    }
  }

  getSupersedingIds() {
    const selected = filter(
      this.data,
      (obj) => typeof obj.pendingRevision === 'object' && obj.pendingRevision != null,
    );
    return pluck(selected, 'id');
  }

  togglePath(path: string, check: boolean) {
    this.data.forEach((row) => {
      if (row.currentDirectoryName === path) {
        row.selected = check;
      }
    });

    this.$target.trigger('data-selected-updated');
    this.updateTable();
    this.updateSelectedTotals();
  }

  getSelectedIds() {
    const selected = filter(this.data, (obj) => obj.selected === true);
    const docIds = pluck(selected, 'id');
    return docIds;
  }

  updateFolderSelect(group: string, path: string) {
    const all = filter(this.data, (obj) => obj.currentDirectoryName === path);
    const selected = filter(all, (obj) => obj.selected === true);

    const $folderTR = this.$target.find(`tr.group.${group}`);
    const $check = $folderTR.find('input.select-folder-check');

    if (all.length === selected.length) {
      $check.prop('checked', 'checked');
      $check.prop('indeterminate', false);
    } else if (selected.length > 0) {
      $check.prop('checked', false);
      $check.prop('indeterminate', true);
    } else {
      $check.prop('checked', false);
      $check.prop('indeterminate', false);
    }
  }

  updateSelectedTotals() {
    const selected = filter(this.data, (obj) => !!obj.selected);

    const selectedCount = selected.length;
    const totalCount = this.data.length;

    const checked = selectedCount === totalCount;
    DocumentList.updateExportOptions(checked);

    const controlPanel = this.$controlPanel;
    const indeterminate = !checked && selectedCount < totalCount;
    this.$target
      .find('.select-all-rows')
      .prop('checked', checked)
      .prop('indeterminate', indeterminate);

    $('.select_count', this.$container)
      .toArray()
      .forEach((count) => {
        const text = selectedCount >= totalCount ? $(count).attr('data-all-text')! : selectedCount;
        $(count).text(text);
      });
    $('.select_count', this.$controlPanel)
      .toArray()
      .forEach((count) => {
        const text = selectedCount >= totalCount ? $(count).attr('data-all-text')! : selectedCount;
        $(count).text(text);

        controlPanel.toggleClass('hide', !selectedCount);
      });
  }

  removeDocument(id: string) {
    const row = this.table.row(`#dt_data_${id}`);
    row.remove();
    this.table.draw();
  }

  moveDocument(id: string, directory: string) {
    const row = this.table.row(`#dt_data_${id}`);
    const data = row.data() as DocumentItem;
    data.currentDirectoryName = directory;
    row.data(data).draw();
  }

  fetch(callback?: () => void | null) {
    this.fetchRequest.abort();

    this.fetchRequest.url = this.fetchUrl;

    this.fetchRequest.extraCallback = (response: DocumentsResponse) => {
      const dataArr: DocumentItem[] = [];

      if (typeof response.data !== 'undefined') {
        $.each(response.data, (index, val) => {
          const data: DocumentItem = val;
          const existing = find(this.data, (obj) => obj.id === data.id);
          if (typeof existing !== 'undefined') {
            data.selected = existing.selected;
          } else {
            data.selected = false;
          }

          data.isNew = indexOf(this.newFiles, data.id) > -1;

          const directory = data.pendingRevision
            ? data.pendingRevision.newDirectoryName
            : data.directoryName;

          data.currentDirectoryName = directory || '';

          data.DT_RowId = `dt_data_${data.id}`;
          dataArr.push(data);
        });
      }
      this.data = dataArr;
      if (this.requiresInit) {
        this.initFunc(this);
      }
      if (typeof callback !== 'undefined') {
        callback();
      }
    };

    this.throttler.add(async () => {
      await this.fetchRequest.submit();
    });
  }

  getData() {
    return this.data;
  }

  toggleTableDisplay(immediateFlag?: boolean) {
    const immediate = immediateFlag == null ? true : immediateFlag;

    if (this.data.length === 0) {
      if (this.$emptyPlaceholder.length > 0 && this.tableShowing === true) {
        if (immediate) {
          this.$target.hide();
          this.$emptyPlaceholder.show();
        } else {
          this.$target.fadeOut(() => {
            this.$emptyPlaceholder.fadeIn();
          });
        }
        this.tableShowing = false;
      } else if (this.$emptyPlaceholder.length === 0 && this.tableShowing) {
        this.$target.show();
        this.tableShowing = true;
      }
    } else if (this.$emptyPlaceholder.length > 0 && !this.tableShowing) {
      if (immediate) {
        this.$target.show();
        this.$emptyPlaceholder.hide();
      } else {
        this.$emptyPlaceholder.fadeOut(() => {
          this.$target.fadeIn();
        });
      }
      this.tableShowing = true;
    }
  }

  drawTable() {
    this.toggleTableDisplay();

    if (this.table != null) {
      this.table.rows().remove();
      this.table.rows.add(this.data).draw();
    }

    // Hacky fix to hide unecessary pationation
    // Unsure if this does anything, but it breaks ts. TODO investigate this CT 21-10-22
    // const $paginateCtn = this.$container.find('.dataTables_paginate');
    // const $span = $paginateCtn.find('span');

    this.$target.trigger('data-updated');
    this.updateSelectedTotals();
  }

  updateTable(fetch?: boolean, cb?: () => void) {
    if (fetch) {
      this.fetch($.proxy(this.drawTable, this));
    } else {
      this.drawTable();
    }
    if (typeof cb !== 'undefined') {
      cb();
    }
  }

  sortByNew() {
    if (this.newCol) {
      this.table.order([this.newCol, 'desc']);
    }
  }

  static updateExportOptions(isChecked: boolean) {
    $('.export-full-docs-register').toggleClass('hide', !isChecked);
    $('.export-custom-docs-register').toggleClass('hide', isChecked);
  }
}
