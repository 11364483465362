/* eslint-disable */
// @generated
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  EntityId: { input: number; output: number; }
}

export enum AccountFileType {
  CAPABILITY_STATEMENT = 'CAPABILITY_STATEMENT',
  INSURANCE = 'INSURANCE',
  QUOTE_ADDITIONAL = 'QUOTE_ADDITIONAL'
}

export enum AccountType {
  ADMIN = 'ADMIN',
  ARCHITECT = 'ARCHITECT',
  BUILDER = 'BUILDER',
  E1 = 'E1',
  ENGINEER = 'ENGINEER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPPLIER = 'SUPPLIER'
}

export interface ActionConsultantImportLogInput {
  actioned: Scalars['Boolean']['input'];
  id: Scalars['EntityId']['input'];
}

export interface AddChurnReasonInput {
  adminText?: InputMaybe<Scalars['String']['input']>;
  churnReasonGroupId: Scalars['EntityId']['input'];
  customerFacingText?: InputMaybe<Scalars['String']['input']>;
  extraInformationPrompt?: InputMaybe<Scalars['String']['input']>;
  isCustomerFacing: Scalars['Boolean']['input'];
  productTags: Array<ChurnReasonProductTag>;
  supportsExtraInformation: Scalars['Boolean']['input'];
  tag: Scalars['String']['input'];
}

export interface AddDiscountToLicenseInput {
  activatedAt: Scalars['DateTime']['input'];
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  licenseId: Scalars['EntityId']['input'];
  offerId: Scalars['EntityId']['input'];
}

export interface AddExperimentInput {
  name: Scalars['String']['input'];
}

export interface AddLicenseSharerInput {
  licenseProviderId: Scalars['EntityId']['input'];
  licenseSharerId: Scalars['EntityId']['input'];
}

export enum AddressBookDataSource {
  BUILDER_AND_PROFILE = 'BUILDER_AND_PROFILE',
  BUILDER_CREATED = 'BUILDER_CREATED',
  IMPORT = 'IMPORT',
  ITP = 'ITP',
  NETWORK_INVITATION_PROFILE = 'NETWORK_INVITATION_PROFILE',
  PROFILE = 'PROFILE',
  RFQ = 'RFQ',
  SHADOW_PROFILE = 'SHADOW_PROFILE'
}

export interface AddressInput {
  address1: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateInput>;
  suburb?: InputMaybe<Scalars['String']['input']>;
}

export interface AssignConsultantInput {
  consultantId: Scalars['EntityId']['input'];
  projectId: Scalars['EntityId']['input'];
  type: Scalars['Int']['input'];
}

export enum ChurnReasonProductTag {
  BUILDER = 'BUILDER',
  CONDEETS = 'CONDEETS',
  SUBBIE = 'SUBBIE',
  SUPPLIER = 'SUPPLIER'
}

export enum ConsultantDiscipline {
  ARCHITECT = 'ARCHITECT',
  ARCHITECT_INTERIOR = 'ARCHITECT_INTERIOR',
  ARCHITECT_LANDSCAPE = 'ARCHITECT_LANDSCAPE',
  CONSULTANT_FIRE = 'CONSULTANT_FIRE',
  CONSULTANT_LIGHTING = 'CONSULTANT_LIGHTING',
  CONSULTANT_SUSTAINABILITY = 'CONSULTANT_SUSTAINABILITY',
  CONSULTANT_TECHNOLOGY = 'CONSULTANT_TECHNOLOGY',
  ENGINEER_ACOUSTIC = 'ENGINEER_ACOUSTIC',
  ENGINEER_CIVIL = 'ENGINEER_CIVIL',
  ENGINEER_ELECTRICAL = 'ENGINEER_ELECTRICAL',
  ENGINEER_FIRE = 'ENGINEER_FIRE',
  ENGINEER_HYDRO = 'ENGINEER_HYDRO',
  ENGINEER_MECH = 'ENGINEER_MECH',
  ENGINEER_STRUCTURAL = 'ENGINEER_STRUCTURAL',
  ENGINEER_THERMAL = 'ENGINEER_THERMAL'
}

export enum ContactType {
  CONSTRUCTION = 'CONSTRUCTION',
  ESTIMATING = 'ESTIMATING'
}

export interface CreateAbsoluteOfferInput {
  absoluteDiscount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  lapsesInDays?: InputMaybe<Scalars['Int']['input']>;
  monthDuration?: InputMaybe<Scalars['Int']['input']>;
  productIds: Array<Scalars['EntityId']['input']>;
}

export interface CreateAccountOffersForAccountsInput {
  accountIds: Array<Scalars['EntityId']['input']>;
  offerId: Scalars['EntityId']['input'];
}

export interface CreateConsultantInput {
  abn?: InputMaybe<Scalars['String']['input']>;
  acn?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  typeIds: Array<Scalars['EntityId']['input']>;
}

export interface CreateConsultantOfficeInput {
  consultantId: Scalars['EntityId']['input'];
  data: OfficeInput;
}

export interface CreateInvoiceFromLicensesInput {
  accountId: Scalars['EntityId']['input'];
  licenseIds: Array<Scalars['EntityId']['input']>;
}

export interface CreateProRataInvoiceFromLicensesInput {
  accountId: Scalars['EntityId']['input'];
  invoiceDueDate: Scalars['DateTime']['input'];
  licenseIds: Array<Scalars['EntityId']['input']>;
}

export interface CreateRelativeOfferInput {
  description: Scalars['String']['input'];
  lapsesInDays?: InputMaybe<Scalars['Int']['input']>;
  monthDuration?: InputMaybe<Scalars['Int']['input']>;
  productIds: Array<Scalars['EntityId']['input']>;
  relativeDiscount: Scalars['Float']['input'];
}

export enum CreditCardCompany {
  AMEX = 'AMEX',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA'
}

export enum CriticalStatus {
  IS_CRITICAL = 'IS_CRITICAL',
  NO_CHANGE = 'NO_CHANGE'
}

export interface DeleteAccountOfferInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteChurnReasonGroupInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteChurnReasonInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteConsultantOfficeInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteOfferInput {
  id: Scalars['EntityId']['input'];
}

export interface EditChurnReasonInput {
  adminText?: InputMaybe<Scalars['String']['input']>;
  churnReasonGroupId: Scalars['EntityId']['input'];
  customerFacingText?: InputMaybe<Scalars['String']['input']>;
  extraInformationPrompt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['EntityId']['input'];
  isCustomerFacing: Scalars['Boolean']['input'];
  productTags: Array<ChurnReasonProductTag>;
  supportsExtraInformation: Scalars['Boolean']['input'];
}

export interface EditConsultantInput {
  data: CreateConsultantInput;
  id: Scalars['EntityId']['input'];
}

export interface EditConsultantOfficeInput {
  data: OfficeInput;
  id: Scalars['EntityId']['input'];
}

export interface EditConsultantProjectAssignmentsInput {
  id: Scalars['EntityId']['input'];
  projectId: Scalars['EntityId']['input'];
  typeIds: Array<Scalars['EntityId']['input']>;
}

export interface EditExperimentInput {
  name: Scalars['String']['input'];
}

export interface EditOfferInput {
  absoluteDiscount?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['EntityId']['input'];
  lapsesInDays?: InputMaybe<Scalars['Int']['input']>;
  monthDuration?: InputMaybe<Scalars['Int']['input']>;
  productIds: Array<Scalars['EntityId']['input']>;
  relativeDiscount?: InputMaybe<Scalars['Int']['input']>;
}

export enum ExperimentConditionOperator {
  MATCH_ALL = 'MATCH_ALL',
  MATCH_ANY = 'MATCH_ANY'
}

export enum ExperimentName {
  ADOPT_LITE_TO_ESSENTIAL_UPGRADE = 'ADOPT_LITE_TO_ESSENTIAL_UPGRADE',
  BLD_REQUEST_PROFILE_UPDATE = 'BLD_REQUEST_PROFILE_UPDATE',
  BUILDER_DOC_RENAME_WIZARD = 'BUILDER_DOC_RENAME_WIZARD',
  BUILDER_MENTION_TEAMMATES_IN_NOTES = 'BUILDER_MENTION_TEAMMATES_IN_NOTES',
  BUILDER_REDACTED_SUBBIE_PROFILE = 'BUILDER_REDACTED_SUBBIE_PROFILE',
  BUILDER_SHAREPOINT_INTEGRATION = 'BUILDER_SHAREPOINT_INTEGRATION',
  /** @deprecated internal */
  BUILDER_TEST = 'BUILDER_TEST',
  BUILDER_VISUAL_DOC_DIFFS = 'BUILDER_VISUAL_DOC_DIFFS',
  /** @deprecated no longer in use */
  CHARGEBEE_CHECKOUT_EXPERIENCE = 'CHARGEBEE_CHECKOUT_EXPERIENCE',
  CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW = 'CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW',
  DISCOVER_LEADS_MGT = 'DISCOVER_LEADS_MGT',
  DISCOVER_LEADS_SPECIFINDER = 'DISCOVER_LEADS_SPECIFINDER',
  DISCOVER_LEADS_VIEWED_QUOTE = 'DISCOVER_LEADS_VIEWED_QUOTE',
  DISCOVER_LEADS_VIEW_OTHER_BUILDERS = 'DISCOVER_LEADS_VIEW_OTHER_BUILDERS',
  DISCOVER_QUOTES_VIEWED_QUOTE = 'DISCOVER_QUOTES_VIEWED_QUOTE',
  DISCOVER_SUNSET_INVITATIONS_BANNER = 'DISCOVER_SUNSET_INVITATIONS_BANNER',
  FUSION_SUBBIE_CHAS_TRIAL = 'FUSION_SUBBIE_CHAS_TRIAL',
  ITP_OTP_AUTHENTICATOR = 'ITP_OTP_AUTHENTICATOR',
  NEW_IN_SUBBIE_CREDIT_EXPERIMENT = 'NEW_IN_SUBBIE_CREDIT_EXPERIMENT',
  PPT_CHARGEBEE_FREE_TO_PAID_FLOW = 'PPT_CHARGEBEE_FREE_TO_PAID_FLOW',
  PRISM_CHARGEBEE_RETENTION_MODULE = 'PRISM_CHARGEBEE_RETENTION_MODULE',
  SPECIFINDER_TRIAL_WITH_ONE_KEYWORD = 'SPECIFINDER_TRIAL_WITH_ONE_KEYWORD',
  SUBBIE_ACCOUNT_ONBOARDING_FORM = 'SUBBIE_ACCOUNT_ONBOARDING_FORM',
  SUBBIE_ACTIVITY_FEED = 'SUBBIE_ACTIVITY_FEED',
  SUBBIE_CORE_TRADES = 'SUBBIE_CORE_TRADES',
  SUBBIE_NETWORK_ENROLMENT_FORM = 'SUBBIE_NETWORK_ENROLMENT_FORM',
  SUBBIE_PRICING_TREATMENT_1 = 'SUBBIE_PRICING_TREATMENT_1',
  SUBBIE_PRICING_TREATMENT_2 = 'SUBBIE_PRICING_TREATMENT_2',
  SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS = 'SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS',
  SUBBIE_SPECIFINDER_INVITATIONS = 'SUBBIE_SPECIFINDER_INVITATIONS',
  /** @deprecated internal */
  SUBBIE_TEST = 'SUBBIE_TEST',
  SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE = 'SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE',
  SUPPLIER_FIFTY_REDACTED = 'SUPPLIER_FIFTY_REDACTED',
  SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API = 'SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API',
  SUPPLIER_PRICING_TREATMENT_1 = 'SUPPLIER_PRICING_TREATMENT_1',
  SUPPLIER_PRICING_TREATMENT_2 = 'SUPPLIER_PRICING_TREATMENT_2',
  SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY = 'SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY',
  SUPPLIER_SSC_TRIAL = 'SUPPLIER_SSC_TRIAL'
}

export enum FeatureName {
  AB_AUDIT = 'AB_AUDIT',
  AB_CARDDAV = 'AB_CARDDAV',
  AB_ENABLED = 'AB_ENABLED',
  AB_MULTIPLE_CONTACT_TYPES_ENABLED = 'AB_MULTIPLE_CONTACT_TYPES_ENABLED',
  ACCOUNT_USERS_RESTRICT = 'ACCOUNT_USERS_RESTRICT',
  ACTION_RFQ_ENABLED = 'ACTION_RFQ_ENABLED',
  ADDRESS_BOOK_APP = 'ADDRESS_BOOK_APP',
  ADVANCED_PROJECT_DETAILS = 'ADVANCED_PROJECT_DETAILS',
  ADVANCED_SEARCH = 'ADVANCED_SEARCH',
  ADVANCED_SPEC_SEARCH = 'ADVANCED_SPEC_SEARCH',
  BUILDER_DIRECTORY = 'BUILDER_DIRECTORY',
  BUILDER_FIXED_PRICE_QUOTES = 'BUILDER_FIXED_PRICE_QUOTES',
  BUILDER_INCOGNITO_ENABLED = 'BUILDER_INCOGNITO_ENABLED',
  BUILDER_PROFILE = 'BUILDER_PROFILE',
  BUILDER_QUOTES = 'BUILDER_QUOTES',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COMPANY_PROFILE_ADVANCED = 'COMPANY_PROFILE_ADVANCED',
  /** Legacy construction feature */
  CONSTRUCTION_CORRESPONDENCE = 'CONSTRUCTION_CORRESPONDENCE',
  /** Legacy construction feature */
  CONSTRUCTION_DELIVERY = 'CONSTRUCTION_DELIVERY',
  /** Legacy construction feature */
  CONSTRUCTION_MODE_ENABLED = 'CONSTRUCTION_MODE_ENABLED',
  CONSULTANT_DETAILS = 'CONSULTANT_DETAILS',
  CONSULTANT_INSIGHTS = 'CONSULTANT_INSIGHTS',
  CREDITS_LITE_TIER = 'CREDITS_LITE_TIER',
  DIRECTORY_ENABLED = 'DIRECTORY_ENABLED',
  ENTERPRISE_SSO = 'ENTERPRISE_SSO',
  ITP_ENABLED = 'ITP_ENABLED',
  ITP_REBRANDING_ENABLED = 'ITP_REBRANDING_ENABLED',
  LEADS_MANAGEMENT = 'LEADS_MANAGEMENT',
  MARKET_INSIGHTS = 'MARKET_INSIGHTS',
  MORE_RESTRICTIVE_BUDGET_PAYWALL = 'MORE_RESTRICTIVE_BUDGET_PAYWALL',
  NETWORK_CONNECTIONS = 'NETWORK_CONNECTIONS',
  NETWORK_SEARCH = 'NETWORK_SEARCH',
  NETWORK_UNIFIED_PROFILE = 'NETWORK_UNIFIED_PROFILE',
  NETWORK_VIEW_MARKET_STATISTICS_ENABLED = 'NETWORK_VIEW_MARKET_STATISTICS_ENABLED',
  OMIT_COMPETITOR_DETAILS = 'OMIT_COMPETITOR_DETAILS',
  PROCUREMENT_PHASE_ENABLED = 'PROCUREMENT_PHASE_ENABLED',
  PRODUCT_INSIGHTS_REPORT = 'PRODUCT_INSIGHTS_REPORT',
  PROJECT_COVER_SHEETS = 'PROJECT_COVER_SHEETS',
  /** @deprecated no longer in use */
  PROJECT_SPEC_SEARCH_MAX = 'PROJECT_SPEC_SEARCH_MAX',
  PROJECT_VIEW_AWARDED_ENABLED = 'PROJECT_VIEW_AWARDED_ENABLED',
  PROJECT_VIEW_TENDERERS = 'PROJECT_VIEW_TENDERERS',
  PROJECT_VIEW_VALUE_MAX = 'PROJECT_VIEW_VALUE_MAX',
  QUOTE_VIEWED_BY_BUILDER = 'QUOTE_VIEWED_BY_BUILDER',
  QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK = 'QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK',
  RFQ_ENGAGEMENT = 'RFQ_ENGAGEMENT',
  SPECIFICATION_INSIGHTS = 'SPECIFICATION_INSIGHTS',
  SPECIFINDER_ADVANCED_SEARCH = 'SPECIFINDER_ADVANCED_SEARCH',
  SPECIFINDER_CAN_SEE_UPGRADE_HOOK = 'SPECIFINDER_CAN_SEE_UPGRADE_HOOK',
  SPECIFINDER_KEYWORD_VARIATIONS = 'SPECIFINDER_KEYWORD_VARIATIONS',
  SPECIFINDER_SAVED_KEYWORDS = 'SPECIFINDER_SAVED_KEYWORDS',
  SSC_5_CREDIT = 'SSC_5_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_10_CREDIT = 'SSC_10_CREDIT',
  SSC_15_CREDIT = 'SSC_15_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_20_CREDIT = 'SSC_20_CREDIT',
  SSC_20_TOP_UP_CREDIT = 'SSC_20_TOP_UP_CREDIT',
  SSC_30_CREDIT = 'SSC_30_CREDIT',
  SSC_50_CREDIT = 'SSC_50_CREDIT',
  SSC_50_TOP_UP_CREDIT = 'SSC_50_TOP_UP_CREDIT',
  /** @deprecated no longer in use, but still in the db */
  STAGE_FILES_ENABLED = 'STAGE_FILES_ENABLED',
  SUBBIE_ADDONS = 'SUBBIE_ADDONS',
  SUBBIE_PROFILE = 'SUBBIE_PROFILE',
  SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT = 'SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT',
  SUBBIE_SEE_ALL_TENDERING_BUILDERS = 'SUBBIE_SEE_ALL_TENDERING_BUILDERS',
  TENDER_CALENDAR_SYNC = 'TENDER_CALENDAR_SYNC',
  TENDER_CREATE_PRIVATE_ENABLED = 'TENDER_CREATE_PRIVATE_ENABLED',
  TENDER_CREATE_PUBLIC_ENABLED = 'TENDER_CREATE_PUBLIC_ENABLED',
  TENDER_HANDOVER = 'TENDER_HANDOVER',
  TENDER_INVITE_MAX = 'TENDER_INVITE_MAX',
  TENDER_MARKET_VIEW = 'TENDER_MARKET_VIEW',
  TENDER_NOTICEBOARD_ENABLED = 'TENDER_NOTICEBOARD_ENABLED',
  TENDER_RETENDER = 'TENDER_RETENDER',
  TENDER_SUGGESTION_ENABLED = 'TENDER_SUGGESTION_ENABLED',
  TENDER_VIEW_MAX = 'TENDER_VIEW_MAX',
  UNREDACT_FIFTY = 'UNREDACT_FIFTY',
  WATCHLIST_ASSIGN_PROJECT_TO_USER = 'WATCHLIST_ASSIGN_PROJECT_TO_USER'
}

export enum InvoiceStatus {
  CANCELLED = 'CANCELLED',
  NOT_PAID = 'NOT_PAID',
  OUTSTANDING = 'OUTSTANDING',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAYMENT_DUE = 'PAYMENT_DUE',
  PENDING = 'PENDING',
  POSTED = 'POSTED',
  VOIDED = 'VOIDED'
}

export enum LettingScheduleCustomColumnIdentifier {
  CUSTOM_COLUMN_1 = 'CUSTOM_COLUMN_1',
  CUSTOM_COLUMN_2 = 'CUSTOM_COLUMN_2'
}

export enum LettingSchedulePriority {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export interface LicenseProRataReductionInput {
  accountId: Scalars['EntityId']['input'];
  dueDate: Scalars['DateTime']['input'];
  licenseIds: Array<Scalars['EntityId']['input']>;
}

export interface OfficeInput {
  address: AddressInput;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
}

export enum ProjectRedactedReason {
  AWARDED = 'AWARDED',
  BUDGET = 'BUDGET',
  CREDIT_EXPERIMENT = 'CREDIT_EXPERIMENT',
  FIFTY_PERCENT = 'FIFTY_PERCENT',
  SINGLE_TENDERER = 'SINGLE_TENDERER'
}

export enum ProjectStatus {
  AWARDED = 'AWARDED',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export enum QuoteLogType {
  ADDED = 'ADDED',
  COMPLETED = 'COMPLETED',
  CREATED_FROM_RFQ = 'CREATED_FROM_RFQ',
  DELETED = 'DELETED',
  DOWNLOADED = 'DOWNLOADED',
  DOWNLOADED_ANON = 'DOWNLOADED_ANON',
  DOWNLOADED_BULK_PACKAGE = 'DOWNLOADED_BULK_PACKAGE',
  DOWNLOADED_BULK_STAGE = 'DOWNLOADED_BULK_STAGE',
  DOWNLOADED_BULK_UNCAT = 'DOWNLOADED_BULK_UNCAT',
  FILE_DOWNLOADED = 'FILE_DOWNLOADED',
  FILE_DOWNLOADED_ANON = 'FILE_DOWNLOADED_ANON',
  PACKAGE_ASSIGNED = 'PACKAGE_ASSIGNED',
  REVISED = 'REVISED',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_UNCAT = 'SUBMITTED_UNCAT',
  VIEWED = 'VIEWED'
}

export interface RemoveConsultantFromProjectInput {
  consultantId: Scalars['EntityId']['input'];
  projectId: Scalars['EntityId']['input'];
  typeIds: Array<Scalars['EntityId']['input']>;
}

export interface RemoveLicenseProviderInput {
  licenseSharerId: Scalars['EntityId']['input'];
}

export interface ReplaceOrphanUserWithAccountUserInput {
  fromUserId: Scalars['EntityId']['input'];
  toUserId: Scalars['EntityId']['input'];
}

export enum RfqQuotingStatus {
  DECLINED = 'DECLINED',
  QUOTED = 'QUOTED',
  QUOTING = 'QUOTING'
}

export enum SearchPhraseCategory {
  BRAND = 'BRAND',
  COMPETITOR = 'COMPETITOR',
  OTHER = 'OTHER',
  PRODUCTS = 'PRODUCTS',
  SERVICE = 'SERVICE',
  TRADE = 'TRADE'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum StageCategory {
  AGED_CARE = 'AGED_CARE',
  CIVIL = 'CIVIL',
  COMMERCIAL = 'COMMERCIAL',
  CORRECTIONS = 'CORRECTIONS',
  DATA_CENTRE = 'DATA_CENTRE',
  DEFENCE = 'DEFENCE',
  EDUCATION = 'EDUCATION',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FIT_OUT = 'FIT_OUT',
  GOVERNMENT = 'GOVERNMENT',
  HEALTH = 'HEALTH',
  HOSPITALITY = 'HOSPITALITY',
  INDUSTRIAL_HEAVY = 'INDUSTRIAL_HEAVY',
  INDUSTRIAL_LIGHT = 'INDUSTRIAL_LIGHT',
  MEDICAL = 'MEDICAL',
  MIXED_USE = 'MIXED_USE',
  OTHER = 'OTHER',
  PHARMACEUTICAL = 'PHARMACEUTICAL',
  RECREATION = 'RECREATION',
  REFURBISHMENT = 'REFURBISHMENT',
  RESIDENTIAL = 'RESIDENTIAL',
  RETAIL = 'RETAIL',
  TOURISM = 'TOURISM'
}

export enum StageType {
  PROCUREMENT = 'PROCUREMENT',
  TENDER = 'TENDER'
}

export interface StateInput {
  id: Scalars['EntityId']['input'];
}

export interface UnassignTypeInput {
  projectId: Scalars['EntityId']['input'];
  type: Scalars['Int']['input'];
}

export interface UpgradeFromStateLicensesToNationalInput {
  accountId: Scalars['EntityId']['input'];
}
