import { useQuery } from '@apollo/client';
import { GET_COMPANIES_BY_NAME } from './queries';
import { GetCompaniesByName, GetCompaniesByNameVariables } from './types/GetCompaniesByName';

type Company = GetCompaniesByName['searchCompanyNames'][0];

const useSearchAddressBookCompanies = (query: string) => {
  const skip = query.length === 0;

  const { data, loading } = useQuery<GetCompaniesByName, GetCompaniesByNameVariables>(
    GET_COMPANIES_BY_NAME,
    {
      variables: { query },
      skip,
    },
  );

  return {
    loading,
    data: data?.searchCompanyNames ?? new Array<Company>(),
  };
};

export type { Company };

export default useSearchAddressBookCompanies;
