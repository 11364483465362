import { GetNetworkInvitesStage_stage_UnrestrictedStage as Stage } from '@builder/features/SubbieNetworkInvitations/hooks/types/GetNetworkInvitesStage';
import { Package } from '@builder/features/SubbieNetworkInvitations/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export const isConnectedCompany = (company: { __typename: string }) => {
  switch (company.__typename) {
    case 'ConnectedE1NetworkCompany':
    case 'ConnectedBuilderNetworkCompany':
      return true;
    default:
      return false;
  }
};

export const getTradeOptionsFromStageTradeList = (
  tradeList?: Stage['trades'],
): ValueLabelOption<number>[] | null => {
  if (!tradeList) return null;

  return tradeList
    .map((trade) => {
      if (trade.stockTrade?.id && trade.stockTrade.name) {
        return {
          value: trade.stockTrade.id,
          label: trade.name,
        };
      }
      return undefined;
    })
    .filter((t) => t !== undefined);
};

export const matchPackageToTrade = (
  packageLabel: string,
  tradeList: Stage['trades'],
): Stage['trades'][number] | undefined => {
  const packageName = packageLabel.toLowerCase().trim();
  const abTrade = tradeList?.find((trade) => trade.name.toLowerCase().trim() === packageName);

  if (abTrade !== undefined) {
    return abTrade;
  }

  return tradeList?.find((trade) => trade.stockTrade?.name.toLowerCase().trim() === packageName);
};

export const isPackageEmpty = (pkg: Package): boolean => pkg.countOfCurrentPackageDocuments === 0;
